import {
    getMobileHistoryURL, postMobileContentsURL,
    getMobileContentByIdURL, putMobileContentPublishURL,
} from '../constants/apiUrls';
import { getAxios, postAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'
import { putAxios } from '../services/Axios/Axios'

// constantes
const initialState = {
    history: [],
    content: null,
    pages: 1,
    activePage: 0,
    isCreated: false,
    isCreating: true,
    loading: false,
    error: null,
};

// types
const MOBILE_SUCCESS = 'MOBILE_SUCCESS';
const HISTORY_CHANGE_PAGE = 'HISTORY_CHANGE_PAGE';
const CREATE_UPDATE_MOBILE_SUCCESS = 'CREATE_UPDATE_MOBILE_SUCCESS';
const MOBILE_BY_ID_SUCCESS = 'MOBILE_BY_ID_SUCCESS';
const MOBILE_ERROR = 'MOBILE_ERROR';
const LOADING_MOBILE = 'LOADING_MOBILE';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_MOBILE:
            return { ...state, loading: true, error: null };
        case MOBILE_SUCCESS:
            return {
                ...state, history: action.payload, pages: action.pages,
                isCreated: false, error: null, loading: false
            };
        case CREATE_UPDATE_MOBILE_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                content: null, history: [], loading: false, error: null
            };
        case HISTORY_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case MOBILE_BY_ID_SUCCESS:
            return {
                ...state, content: action.payload, loading: false, error: null
            };
        case MOBILE_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                history: [],
                content: null,
            };
        default:
            return state;
    }
};

export const getHistory = (filterBy = [], page = 0, pageSize = 10000, loading = false) => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_MOBILE });
    try {
        const { user } = getState().auth;
        const res = await postAxios(getMobileHistoryURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "service": "",
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            const history = res.data.response;
            return dispatch({
                type: MOBILE_SUCCESS,
                payload: history,
                pages: res.data.totalPages
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: MOBILE_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingMobileContent"
        });
    }
};

export const getContentById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_MOBILE });
    try {
        const res = await getAxios(getMobileContentByIdURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: MOBILE_BY_ID_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: MOBILE_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingMobileContent"
        });
    }
};

export const postUpdateContent = (pages, versionName, status, isCreating, pagesDelete, contentsDelete,
    imagesToDelete, fieldsToDelete) => async (dispatch, getState) => {
        dispatch({ type: LOADING_MOBILE });
        try {
            const { user } = getState().auth;
            const actualDate = new Date();
            actualDate.setTime(actualDate.getTime() - actualDate.getTimezoneOffset() * 60 * 1000);

            const bodyFormData = new FormData();
            bodyFormData.append('OrganizationSettingId', user.organizationId);
            bodyFormData.append('UserId', user.id);
            bodyFormData.append('Status', status);
            bodyFormData.append('Name', versionName);
            bodyFormData.append('Date', actualDate.toUTCString());
            if (!isCreating) {
                const { content } = getState().mobile;
                bodyFormData.append('Id', content.id);
                pagesDelete.forEach((del, index) => {
                    bodyFormData.append(`PagesToDelete[${index}]`, del);
                });
                contentsDelete.forEach((del, index) => {
                    bodyFormData.append(`ObjectsToDelete[${index}]`, del);
                });
                imagesToDelete.forEach((del, index) => {
                    bodyFormData.append(`ImagesToDelete[${index}]`, del);
                });
                fieldsToDelete.forEach((del, index) => {
                    bodyFormData.append(`FieldsToDelete[${index}]`, del);
                });
            }

            pages.forEach((page, pageIndex) => {
                bodyFormData.append(`Pages[${pageIndex}].Name`, page.page);
                bodyFormData.append(`Pages[${pageIndex}].Id`, (page.id && !isCreating) ? page.id : 0);
                page.content.forEach((content, contentIndex) => {
                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].Id`, (content.id && !isCreating) ? content.id : 0);
                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].Position`, contentIndex);
                    const languages = [];
                    Object.keys(content.value).forEach(key => {
                        if (content.value[key] !== null)
                            languages.push(key);
                    });

                    languages.forEach((lng, lngIndex) => {
                        const value = content.value[lng];
                        if (value) {
                            bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ObjectId`, (content.id && !isCreating) ? content.id : 0);
                            bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].LanguageId`, lng);
                            if (content.contentType === 'TEXT') {
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].TextId`, (value.id && !isCreating) ? value.id : 0);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Text.Text1`, value.text);
                            } else if (content.contentType === 'IMAGE') {
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageId`, (value.id && !isCreating) ? value.id : 0);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Image.FileId`, value.fileId);
                                value.file &&
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Image.Image`, value.file);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Image.Height`, value.height);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Image.Width`, value.width);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Image.Check`, value.check);
                            } else if (content.contentType === 'GALLERY') {
                                let imageCount = 0;
                                value.forEach((item) => {
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageGalleryId`, (item.id && !isCreating) ? item.id : 0);
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageGallery.FolderId`, item.folderId ? item.folderId : 0);
                                    if (item.file) {
                                        bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageGallery.Check`, item.check);
                                        bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageGallery.Images[${imageCount}].Image`, item.file);
                                        bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageGallery.Images[${imageCount}].Height`, item.height);
                                        bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].ImageGallery.Images[${imageCount}].Width`, item.width);
                                        imageCount += 1;
                                    }
                                });
                            } else if (content.contentType === 'MAP') {
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].MapId`, (value.id && !isCreating) ? value.id : 0);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Map.CoordinatesGps`, value.map);
                            } else if (content.contentType === 'FORM') {
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Form.Name`, 'form');
                                value.forEach((item, itemIndex) => {
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].FormId`, (item.formId && !isCreating) ? item.formId : 0);
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Form.Fields[${itemIndex}].Id`, (item.id && !isCreating) ? item.id : 0);
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Form.Fields[${itemIndex}].Label`, item.label);
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Form.Fields[${itemIndex}].Type`, item.type);
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Form.Fields[${itemIndex}].Placeholder`, item.placeholder ? item.placeholder : 'null');
                                    bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Form.Fields[${itemIndex}].Required`, item.required);
                                });
                            } else if (content.contentType === 'USER_CARD') {
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCardId`, (value.id && !isCreating) ? value.id : 0);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCard.UserId`, value.userId);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCard.FullName`, value.fullName);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCard.Email`, value.email);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCard.Phone`, value.phone);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCard.Position`, value.position);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].UserCard.Downloadable`, value.downloadable === 'true');
                            }
                            else if (content.contentType === 'VIDEO') {
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].VideoId`, (value.id && !isCreating) ? value.id : 0);
                                bodyFormData.append(`Pages[${pageIndex}].Objects[${contentIndex}].ObjectLanguages[${lngIndex}].Video.Url`, value.ytid);
                            }
                        }
                    });
                });
            });

            const res = await postAxios(postMobileContentsURL, bodyFormData)
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: CREATE_UPDATE_MOBILE_SUCCESS,
                    response: res.data.response
                });
            }
            throw new Error();

        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: MOBILE_ERROR,
                payload: error.response ? error.response.data.message : "common:errorSavingMobileContent"
            });
        }
    };

export const publish = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_MOBILE });
    try {
        const res = await putAxios(putMobileContentPublishURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch(getHistory());
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: MOBILE_ERROR,
            payload: error.response ? error.response.data.message : "common:errorPublishingContent"
        });
    }
};

export const changeHistoryPage = (page) => (dispatch) => {
    dispatch({
        type: HISTORY_CHANGE_PAGE,
        activePage: page - 1
    });
};