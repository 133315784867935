import {
    getPaymentsURL, getSubscriptionByIdURL, gpSessionURL, signupURL, cardTokenizeURL, changeSubscriptionPlanURL, cancelSubscriptionURL, changePaymentMethodURL, gpcheckout, registerSubscriptionURL, submitSubscriptionURL
} from '../constants/apiUrls';
import { postAxios, getAxios, postgpAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'
//import JSEncrypt from 'jsencrypt';
// constantes
const initialState = {
    subscription: null,
    payments: null,
    expiration: null,
    alertMessage: null,
    loading: false,
    pages: 1,
    activePage: 0,
    error: null,
    response: null,
    card: "",
    billingPeriod: "",
    subscription: {},
    currentPlan: "",
    cardBrand: "",
    payments: "",
};

// types
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SUBS_ERROR = 'SUBS_ERROR';
const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS';
const CHANGE_PAYMENT_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESS';
const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS';
const LOADING_SUBSCRIPTION = 'LOADING_SUBSCRIPTION';
const PAYMENT_CHANGE_PAGE = 'PAYMENT_CHANGE_PAGE';
const LOADING_SIGNUP = 'LOADING_SIGNUP';
const ALERT_MESSAGE = 'ALERT_MESSAGE';
const LENGUAGE_ID_SUCCESS = 'LENGUAGE_ID_SUCCESS';
//const aesjs = require('aes-js');
//var rsa_ = new JSEncrypt();
//rsa_.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCohNAdtqUpN7mxelg9BFx66FOHs9vnZKL0Kt0TVbiI32oMdBO4gzdBEri3rNLPgqWY19NmbJ88TdDVAQX8GxWcF72/7M3d7p6UBpRrYEAuCpd6C696NWarR3uigNJ8dxUE3xGcXwVrpLror3/XK8Qakdz11cAUTb0DGbe/kq/yMQIDAQAB");

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_SIGNUP:
            return { ...state, loading: true, response: " Executing your subscription payment, please wait a moment.", error: null };
        case LOADING_SUBSCRIPTION:
            return { ...state, loading: true, error: null };
        case SIGNUP_SUCCESS:
            return {
                ...state, response: action.response, checkoutError: action.payload,
                error: null, isLogged: false, loading: false, redirectToThankYou: true
            };
        case SUBS_ERROR:
            return {
                ...state, error: action.payload, loading: false, alertMessage: action.alert,
                response: action.payload
            };
        case PAYMENTS_SUCCESS:
            return {
                ...state, payments: action.payload ? action.payload :
                    [{ dateOfCharge: "2022-01-02", chargeAmount: "29.5", description: "November 2022 - Startup" },
                    { dateOfCharge: "2022-11-11", chargeAmount: "9.5", description: "Additional User pack" },
                    { dateOfCharge: "2022-12-12", chargeAmount: "29.5", description: "December 2022 - Startup" }],
                pages: action.pages,
                activePage: action.activePage,
                error: null, loading: false
            };
        case CHANGE_PLAN_SUCCESS:
            return {
                ...state, checkoutError: false,
                currentPlan: action.payload.subscription.currentPlan ? action.payload.subscription.currentPlan : "2",
                subscription: action.payload.subscription ? action.payload.subscription : {},
                error: null, loading: false, response: action.response,
            };
        case CHANGE_PAYMENT_SUCCESS:
            return {
                ...state, checkoutError: false,
                card: action.payload.cardLast4Digits ? action.cardLast4Digits : "1234",
                cardBrand: action.payload.paymentProcessor ? action.paymentProcessor : "Visa",
                error: null, loading: false, response: action.response,
            };
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state, checkoutError: false,
                error: null, loading: false, response: action.response,
            };
        case GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscription: action.payload ? action.payload : {},
                card: action.payload.cardLast4Digits ? action.cardLast4Digits : "1234",
                cardBrand: action.payload.paymentProcessor ? action.paymentProcessor : "Visa",
                billingPeriod: action.payload.billingPeriod ? action.billingPeriod : "MONTHLY",
                currentPlan: action.payload.subscriptionType ? action.payload.subscriptionType : "2",
                error: null, loading: false
            };
        case CHECKOUT_ERROR:
            return {
                ...state, error: action.payload, alertMessage: action.alert, response: action.response, loading: false
            };
        case PAYMENT_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case ALERT_MESSAGE:
            return { ...state, alertMessage: action.payload };
        case LENGUAGE_ID_SUCCESS:
            return { ...state, lenguageId: action.payload };
        default:
            return state;
    }
};

// actions

export const signUpAction = (subscription) => async (dispatch) => {
    dispatch({ type: LOADING_SIGNUP });
    let gotSession = false;
    try {
        const cardDataObject = {
            card: {

                cardHolder: subscription.cardHolderName,
                expirationDate: {
                    year: Math.floor(subscription.exp.substring(2, 4)),
                    month: Math.floor(subscription.exp.substring(5, 7))
                },
                "cardNumber": subscription.cardN,
                "cvc": subscription.CVV,
                "nickname": "fos-sale" //more than 5 chars and less tan 50 chars
            },

        };
        console.log(cardDataObject);
        //Encrypting Card data to lizst tokens

        const res = await getAxios(gpSessionURL);
        if (res.status === 200) {
            gotSession = true;
            console.log("got session?", gotSession);
            let responseData = JSON.parse(res.data.response);
            //console.log("session token response",res.data.response,responseData);
            //  console.log("session : ",responseData.session);
            // console.log("token : ",responseData.token);
            const token = responseData.token;
            const session = responseData.session;
            localStorage.setItem('token', token);
            localStorage.setItem('gpsession', session);
            //var EncryptedCardData = pack(cardDataObject, session);
            var EncryptedCardData = "";
            /* 
            console.log('ld', EncryptedCardData.ld,);
            console.log('lk', EncryptedCardData.lk,);
            // localStorage.setItem('gpsession', session);
            // console.log("subscriptionForm provided",subscription);
            // console.log('Card data encrypted:', JSON.stringify(EncryptedCardData));
             
             const cardTokenRes = await postAxios(cardTokenizeURL,{
                 token:token,
                 session:session,
                 ld:EncryptedCardData.ld,
                 lk:EncryptedCardData.lk
             });
             */
            const headers = {
                // 'Content-Type': 'application/json',
                'liszt-token': token
            }
            const cardTokenRes = await postgpAxios(gpcheckout + "tokenize", {
                session: session,
                ld: EncryptedCardData.ld,
                lk: EncryptedCardData.lk
            }, {
                headers: headers
            });
            //console.log(cardTokenRes.data.status);
            //console.log(cardTokenRes.data.result.last_digits);
            let cardTokenResponse = cardTokenRes.data;
            console.log("card tokenization response status : ", cardTokenResponse.status);
            // console.log(cardTokenResponse);
            console.log("submitting account creation");
            let discountTerm = subscription.discountPeriod ? subscription.discountPeriod : "0";
            let cardTokenError = {
                message: "error on subscription payment process",
                status: cardTokenResponse.status,
                alert: "There was an error with your payment method please verify your information, or try another method.",
                details: cardTokenResponse
            }
            if (cardTokenResponse.status >= 200) {
                const createSubRes = await postAxios(submitSubscriptionURL, {
                    firstName: subscription.firstName,
                    lastName: subscription.lastName,
                    email: subscription.email,
                    orgName: subscription.orgName,
                    cardHolderName: subscription.cardHolderName,
                    countryCodeId: subscription.countryCodeId,
                    cardLast4: cardTokenResponse.result.last_digits,
                    paymentBrand: responseData.brand,
                    exp: subscription.exp,
                    expYear: subscription.exp.substring(5, 7),
                    expMonth: subscription.exp.substring(0, 4),
                    termsAcceptance: subscription.termsAcceptance,
                    subtotal: subscription.subtotal.replace('$', ''),
                    discount: subscription.discount ? subscription.discount : "0",
                    discountPeriod: discountTerm === "Permanent" ? "0" : "0" + discountTerm,//force to send  as string (parsed on BE)
                    planName: subscription.planName ? subscription.planName : "Startup",
                    final: subscription.final,
                    token: cardTokenResponse.result.token,
                    //customerId: "xxxx",

                });
                let subscriptionsResponse = JSON.parse(createSubRes.data.response);
                console.log("subscription submittal response : ", subscriptionsResponse.status, subscriptionsResponse);
                let creationError = {
                    message: "error on subscription payment process",
                    status: subscriptionsResponse.status,
                    alert: "There was an error with your payment method, please verify your information, or try another method.",
                    details: subscriptionsResponse
                }
                //console.log(creationError);
                if (subscriptionsResponse.status >= 200) {
                    console.log("recording account on system");

                    const registerSubRes = await postAxios(registerSubscriptionURL, {
                        GPsubscriptionId: subscriptionsResponse.subscriptionId,
                        firstName: subscription.firstName,
                        lastName: subscription.lastName,
                        email: subscription.email,
                        orgName: subscription.orgName,
                        cardHolderName: subscription.cardHolderName,
                        countryCodeId: subscription.countryCodeId,
                        cardLast4: cardTokenResponse.result.last_digits,
                        paymentBrand: cardTokenResponse.brand,
                        exp: subscription.exp,
                        expYear: subscription.exp.substring(5, 7),
                        expMonth: subscription.exp.substring(0, 4),
                        termsAcceptance: subscription.termsAcceptance,
                        subtotal: subscription.subtotal.replace('$', ''),
                        discount: subscription.discount ? subscription.discount : "0",
                        discountPeriod: discountTerm === "Permanent" ? "0" : "0" + discountTerm,//force to send  as string (parsed on BE)
                        planName: subscription.planName ? subscription.planName : "Startup",
                        final: subscription.final,
                        customerId: "xxxx",

                    });
                    return dispatch({
                        type: SIGNUP_SUCCESS,
                        response: "Succesfully created subscription, you will receive an email confirmation shortly",
                        payload: "Succesfully created subscription, you will receive an email confirmation shortly",
                    });
                    /*
                        let recordError = {
                            message: "error on registering account internally, but subscription has been created",
                            status: 199,
                            details: registerSubRes.status
                            alert: "Your account"
                        }
                        console.log("register on db response : ",registerSubRes.data.statusCode, registerSubRes);
                    
                        if (registerSubRes.data.statusCode >= 200) {

                        }
                        else
                        {
                            throw new Error(recordError);
                        }
                    */
                }
                else {
                    throw new Error(creationError);
                }
            }
            else {

                console.log("error on card tokenization");
                // console.log(cardTokenResponse.status);
                //console.log(cardTokenResponse);
                throw new Error(cardTokenError);
            }


        }

        else {
            let sessionError = {
                message: "error connecting to payment gateway, please try again later or contact us directly.",
                status: 500,
                details: "unable to get greenpay session"
            }
            throw new Error(sessionError);
        }
    } catch (error) {
        console.log(error);
        //in case an error is triggered before step error is composed, defaulting to 2 possible error messages.

        var messageError = !gotSession ? "A connection error occured, please try again later." : "There was an error with your payment method, please verify your information, or try another method.";
        if (error.status === 403)
            messageError = "auth:errorExpiredPass";

        dispatch({
            type: CHECKOUT_ERROR,
            payload: error.message ? error.message : messageError,
            alert: messageError
        });
    }
};

export const cancelSubscription = (subscriptionId) => async (dispatch, getState) => {
    try {

        dispatch({ type: LOADING_SUBSCRIPTION });
        const res = await postAxios(cancelSubscriptionURL, {
            id: subscriptionId
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CANCEL_SUBSCRIPTION_SUCCESS,
                payload: res.data.response,
                response: "Succesfully changed subscription plan"
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: SUBS_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}
export const changeSubscriptionPlan = (planId, subscriptionId,organizationId) => async (dispatch, getState) => {
    try {
        //console.log(selectedPlan,subscription,subscription.greenpaySubscriptionId);

        dispatch({ type: LOADING_SUBSCRIPTION });
        const { user } = getState().auth;
        const res = await postAxios(changeSubscriptionPlanURL, {
            planId: planId,
            organizationId: organizationId,
            subscriptionId: subscriptionId
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(getSubscriptionByOrgId());
            
            return dispatch({
                type: CHANGE_PLAN_SUCCESS,
                payload: res.data.response,
                response: "Succesfully changed subscription plan"
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: SUBS_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"

            });
    }
}
export const changePaymentMethod = (subscriptionForm, subscriptionId) => async (dispatch, getState) => {
    try {

        dispatch({ type: LOADING_SUBSCRIPTION });
        const { user } = getState().auth;
        const res = await postAxios(changePaymentMethodURL, {
            CardHolder: subscriptionForm.cardHolderName,
            CardNumber: subscriptionForm.cardN,
            CVC: subscriptionForm.CVV,
            nickname: "fos-sale", //more than 5 chars and less tan 50 chars,
            year: Math.floor(subscriptionForm.exp.substring(2, 4)),
            month: Math.floor(subscriptionForm.exp.substring(5, 7)),
            subscriptionId: subscriptionId,
            session: "",
            token: ""
        });
        console.log("log", res);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CHANGE_PAYMENT_SUCCESS,
                payload: res.data.response,
                response: "Succesfully changed payment method"
            });

        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: SUBS_ERROR,
                payload: error.response ? error.response.data.message : "userProfile:paymentMethodError",
                alert: "userProfile:paymentMethodError"
            });
    }
}
export const getSubscriptionByOrgId = () => async (dispatch, getState) => {
    try {

        dispatch({ type: LOADING_SUBSCRIPTION });
        const { user } = getState().auth;
        const res = await getAxios(getSubscriptionByIdURL, { id: user.organizationId });
        // console.log("subscription response",res,res.data);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: GET_SUBSCRIPTION_SUCCESS,
                payload: res.data.response,

            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: SUBS_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}
export const getPaymentsBySubscriptionId = (filterBy = [], page = 0, pageSize = 1000, loading = false, service = "") => async (dispatch, getState) => {
    try {
        dispatch({ type: LOADING_SUBSCRIPTION });
        const { user } = getState().auth;
        const res = await postAxios(getPaymentsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "service": service,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: PAYMENTS_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages,
                activePage: (res.data.totalPages < page + 1) ? 0 : page
            });
        }
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: SUBS_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}
export const changePaymentPage = (page) => (dispatch) => {
    dispatch({
        type: PAYMENT_CHANGE_PAGE,
        activePage: page - 1
    });
};
export const alertMessage = () => dispatch => {
    dispatch({
        type: ALERT_MESSAGE,
        payload: "newPasswordAlert"
    });
};
export const clearError = () => dispatch => {
    dispatch({
        type: SUBS_ERROR,
        payload: ''
    });
}

export const clearResponse = () => dispatch => {
    dispatch({ type: CLEAR_RESPONSE });
};


/*
function pack(obj, session, pair_) {
    var pair = (pair_ !== undefined) ? pair_ : generateAESPairs();
    var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj));
    var aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s));
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    var returnObj = {
        session: session,
        ld: encryptedHex,
        lk: rsa_.encrypt(JSON.stringify(pair))
    };
    return returnObj;
}
function generateAESPairs() {
    var key = []
    var iv = 0;
    for (var k = 0; k < 16; k++) {
        key.push(Math.floor(Math.random() * 255))
    }
    // eslint-disable-next-line
    for (var k = 0; k < 16; k++) {
        iv = Math.floor(Math.random() * 255)
    }
    return {
        k: key,
        s: iv
    }
}
*/