import React, { useState, useEffect } from "react";
import { InputLabel, Typography } from "@mui/material";
import SelectFieldComponent from "../../../components/SelectField";
import TextFieldComponent from "../../../components/TextField";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessesFilter, getOfficesFilter } from "../../../redux/BusinessDuck";
import { getClients, clearClients } from "../../../redux/ClientDuck";
import { useTranslation } from 'react-i18next';
const FirstStep = ({ setValues, authorization, error }) => {
    const { t } = useTranslation(['authorization','common']);
    const businesses = useSelector(store => store.business.businesses);
    const clientsRedux = useSelector(store => store.client.clients);
    const officesRedux = useSelector(store => store.business.offices);
    const [companyId, setCompanyId] = useState('');
    const [companies, setCompanies] = useState([]);
    const [officeId, setOfficeId] = useState('');
    const [offices, setOffices] = useState([]);
    const [clientId, setClientId] = useState('');
    const [clients, setClients] = useState([]);
    const dispatch = useDispatch();

    const onChangeCompany = e => {
        setCompanyId(e.target.value);
        setOffices([]);
        setClients([]);
        setValues({
            ...authorization, companyId: e.target.value,
            officeId: '', clientId: ''
        });
    };

    const onChangeOffice = e => {
        setOfficeId(e.target.value);
        setClients([]);
        setValues({
            ...authorization, officeId: e.target.value,
            clientId: ''
        });
    };

    const onChangeClient = e => {
        setClientId(e.target.value);
        setValues({ ...authorization, clientId: e.target.value });
    };

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        if (authorization.officeId && officesTemp.find(o => String(o.key) === String(authorization.officeId))) {
            setOfficeId(authorization.officeId);
        } else {
            setOfficeId('');
        }
        // eslint-disable-next-line
    }, [companyId, officesRedux]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== '' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);

    useEffect(() => {
        if (officeId) {
            dispatch(getClients([
                { search: 'false', filterBy: 'disabled' },
                { search: String(officeId), filterBy: 'office' },
            ], 0, 10000, false, 'authorization'));
        } else {
            dispatch(clearClients());
        }
    }, [dispatch, officeId]);

    useEffect(() => {
        const clientList = [];
        clientsRedux.forEach(item => {
            clientList.push({ key: item.id, value: item.name });
        });
        setClients(clientList);
        // eslint-disable-next-line
    }, [clientsRedux]);

    const onChange = e => {
        const { name, value } = e.target;
        setValues({ ...authorization, [name]: value });
    };

    useEffect(() => {
        setCompanyId(authorization.companyId);
    }, [companyId, authorization.companyId]);

    useEffect(() => {
        setOfficeId(authorization.officeId);
    }, [officeId, authorization.officeId]);

    useEffect(() => {
        setClientId(authorization.clientId);
    }, [clientId, authorization.clientId]);

    return (
        <div>
            <div className="container-app-div">
                <Typography className="app-form-title" style={{ marginTop: 22 }}>{t("subtitle5")}</Typography>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    width: '100%', justifyContent: 'space-between',
                }}>
                    <div style={{ width: '31%' }}>
                        <InputLabel className="label-app">{t("headerTbl13")}</InputLabel>
                        <SelectFieldComponent name="businessName"
                            valueInput={companyId}
                            onChange={onChangeCompany}
                            options={companies}
                            errorInput={error.companyId}
                        />
                    </div>
                    <div style={{ width: '31%' }}>
                        <InputLabel className="label-app">{t("headerTbl14")}</InputLabel>
                        <SelectFieldComponent name="officesName"
                            valueInput={officeId}
                            onChange={onChangeOffice}
                            options={offices}
                            errorInput={error.officeId}
                        />
                    </div>
                    <div style={{ width: '31%' }}>
                        <InputLabel className="label-app">{t("headerTbl15")}</InputLabel>
                        <SelectFieldComponent name="businessName"
                            valueInput={clientId}
                            onChange={onChangeClient}
                            options={clients}
                            errorInput={error.clientId}
                        />
                    </div>
                </div>
            </div>
            <div className="container-app-div">
                <Typography className="app-form-title">{t("subtitle6")}</Typography>
                <div style={{ marginBottom: 24 }}>
                    <InputLabel className="label-app">{t("titleLbl")}</InputLabel>
                    <TextFieldComponent name="title" valueInput={authorization.title}
                        callback={onChange.bind(this)} placeholder={t("titlePlh")}
                        errorInput={error.title} />
                </div>
                <InputLabel className="label-app">{t("descriptionLbl")}</InputLabel>
                <TextFieldComponent name="description" row={4} valueInput={authorization.description}
                    callback={onChange.bind(this)} maxLength={500}
                    placeholder={t("descriptionPlh")}
                    errorInput={error.description} />
            </div>
        </div>
    );
}
export default FirstStep;