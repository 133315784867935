// Base URL
// const baseURL = 'https://fos-dev.softonitg.com/api/';
//const baseURL = 'https://fos-dev.softonitg.com:444/api/';
const baseURL = 'http://52.203.7.205:8080/api/';
//const baseURL = 'https://localhost:44379/api/';

//Payment Gateway URL
//export const signUpURL = "https://sandbox-merchant.greenpay.me/subscriptions";
export const gpmerchant = "https://sandbox-merchant.greenpay.me/";
export const gpcheckout = "https://sandbox-checkout.greenpay.me/";

// Auth URLs
export const loginURL = `${baseURL}Authenticate/login`;
export const resendLoginEmail = `${baseURL}User/resendFirstLoginEmail`;
export const gpSessionURL = `${baseURL}Subscription/getGPSession`;
export const cardTokenizeURL = `${baseURL}Subscription/getCardToken`;
export const registerSubscriptionURL = `${baseURL}Subscription/create`;
export const submitSubscriptionURL = `${baseURL}Subscription/submitSubscription`;
export const getPaymentsURL = `${baseURL}Subscription/payments`;
export const getSubscriptionByIdURL = `${baseURL}Subscription/id`;
export const changeSubscriptionPlanURL = `${baseURL}Subscription/switchPlan`;
export const cancelSubscriptionURL = `${baseURL}Subscription/cancelSubscription`;
export const changePaymentMethodURL = `${baseURL}Subscription/editPaymentMethod`;
export const forgotPasswordURL = `${baseURL}Authenticate/forgotPassword`;
export const resetPasswordURL = `${baseURL}Authenticate/resetPassword`;
export const changePasswordURL = `${baseURL}User/changePassword`;
export const getUserURL = `${baseURL}User/currentUser`;
export const getRoleUserURL = `${baseURL}User/UserRole/`;


export const getOrganizationDetailURL = `${baseURL}OrganizationSetting/detail`;
export const getOrganizationAdminURL = `${baseURL}OrganizationSetting/get-admin`;
export const getOrganizationIdURL = `${baseURL}OrganizationSetting/id`;
export const putOrganizationURL = `${baseURL}OrganizationSetting/edit`;

export const getAppSettingByIdURL = `${baseURL}AppSetting/id`;
export const postAppSettingChangeLogoURL = `${baseURL}AppSetting/logo-change-request`;
export const postAppSettingURL = `${baseURL}AppSetting/create`;
export const putAppSettingURL = `${baseURL}AppSetting/edit`;

export const getLenguageURL = `${baseURL}Language`;
export const getCountryURL = `${baseURL}CountryCode`;
export const getCountriesENURL = `${baseURL}CountryCode/en`;

export const getCompanyURL = `${baseURL}Company`;
export const postCompanyURL = `${baseURL}Company/create`;
export const getCompanyByIdURL = `${baseURL}Company/id`;
export const putCompanyDisableURL = `${baseURL}Company/disable?id=`;
export const putCompanyActiveURL = `${baseURL}Company/activate?id=`;
export const putCompanyURL = `${baseURL}Company/edit`;

export const getOfficeURL = `${baseURL}Office`;
export const putOfficeDisableURL = `${baseURL}Office/disable?id=`;
export const putOfficeActiveURL = `${baseURL}Office/activate?id=`;

export const getClientsURL = `${baseURL}Client`;
export const postClientURL = `${baseURL}Client/create`;
export const putClientURL = `${baseURL}Client/edit`;
export const getClientByIdURL = `${baseURL}Client/id`;
export const putClientDisableURL = `${baseURL}Client/disable?id=`;
export const putClientActiveURL = `${baseURL}Client/activate?id=`;

// Note: Subject and Matter are both FE and BE synonims to what is seen on design as 'Task'
//export const getSubjectsURL = `${baseURL}Subject`;
export const getSubjectsURL = `${baseURL}Subject/filter`;
export const postSubjectURL = `${baseURL}Subject`;
export const putSubjectURL = `${baseURL}Subject`;
export const getSubjectByIdURL = `${baseURL}Subject/`;
export const putSubjectDisableURL = `${baseURL}Subject/disable/`;
export const putSubjectActiveURL = `${baseURL}Subject/activate/`;
export const subjectDeleteFileURL = `${baseURL}Subject/delete-file`;
export const putSubjectAddFileURL = `${baseURL}Subject/add-file`;

export const getUsersURL = `${baseURL}User`;
export const getUserByIdURL = `${baseURL}User/id`;
export const postUserURL = `${baseURL}User/create-organization-user`;
export const putUserURL = `${baseURL}User/edit`;
export const putUserDisableURL = `${baseURL}User/disable?id=`;
export const putUserActiveURL = `${baseURL}User/activate?id=`;

export const getPermissionByIdURL = `${baseURL}Permission/id`;
export const getPermissionCopyURL = `${baseURL}Permission/copy`;
export const putPermissionURL = `${baseURL}Permission/edit`;

export const getAssignUser = `${baseURL}Assign/client`;
export const updateUserAssignedClients = `${baseURL}Assign/client-staff`;
export const getAssignedClientStaffURL = `${baseURL}Assign/assigned-client-staff`;
export const getPendingClientStaffURL = `${baseURL}Assign/pending-assign-client-staff`;
export const putAssignClientStaffURL = `${baseURL}Assign/enable-client-staff?userId=`;
export const getClientStaffURL = `${baseURL}User/:id/client-staff`;
export const postClientStaffURL = `${baseURL}User/create-clientstaff`;
export const pendingClientStaffByIdURL = `${baseURL}Assign/pending-assign-client-staff/`;

export const putAssignedUserDisableURL = `${baseURL}User/disable?id=`;
export const putAssignedUserActiveURL = `${baseURL}User/activate?id=`;
export const putMobileAssignedUserDisableURL = `${baseURL}Mobile/disable?id=`;
export const putMobileAssignedUserActiveURL = `${baseURL}Mobile/activate?id=`;

export const spamClientURL = `${baseURL}Assign/spam/`;
export const noSpamClientURL = `${baseURL}Assign/not-spam/`;

export const getNotificationsURL = `${baseURL}Notification`;
export const getNotificationByIdURL = `${baseURL}Notification/`;
export const postNotificationURL = `${baseURL}Notification/create`;

export const getFileURL = `${baseURL}File/preview?id=`;

export const validateFolderURL = `${baseURL}Folder/delete-validation/`;

export const getDocumentsURL = `${baseURL}File/GetByFolderId`;
export const getURLDocumentURL = `${baseURL}File/download/PreSignedUrl/`;
export const getFoldersURL = `${baseURL}Folder`;
export const postFolderCreateURL = `${baseURL}Folder/create`;
export const postFileCreateURL = `${baseURL}File`;
export const deleteFileURL = `${baseURL}Folder/delete-file/`;
export const deleteFolderURL = `${baseURL}Folder/delete/`;

export const getAuthorizationsURL = `${baseURL}Authorization`;
export const getAuthorizationByIdURL = `${baseURL}Authorization/`;
export const postAuthorizationURL = `${baseURL}Authorization/create`;
export const getLogsURL = `${baseURL}LogBook`;

export const getHelpURL = `${baseURL}User/user-screen`;
export const putHelpURL = `${baseURL}User/edit/user-screen`;

export const getMobileHistoryURL = `${baseURL}MobileContent/history`;
export const postMobileContentsURL = `${baseURL}MobileContent/create`;
export const getMobileContentByIdURL = `${baseURL}MobileContent/`;
export const putMobileContentPublishURL = `${baseURL}MobileContent/publish/`;
export const putMobileContentsURL = `${baseURL}MobileContent/edit`;