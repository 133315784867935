import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Typography, InputLabel, Button,
    Avatar, AppBar, Toolbar, IconButton,
    Drawer, List, ListItemText, ListItem
} from "@mui/material";
import Background from '../../../assets/Images/Phone.png';
import TextFieldComponent from "../../../components/TextField";
import GoogleMap from "./Map";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Preview.css';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getFileURL } from '../../../constants/apiUrls';
import { getUsers } from '../../../redux/UserDuck';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Preview = ({ page = null, pages = [], onChangePage, record = true }) => {

    const { t } = useTranslation(['mobileContent', 'common']);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const system = useSelector(store => store.system.system);
    const [language, setLanguage] = useState(defaultLanguageId);
    const [languageList, setLanguageList] = useState([]);
    const [open, setOpen] = useState(false);
    const users = useSelector(store => store.user.users);
    const dispatch = useDispatch();

    const [pageSelected, setPageSelected] = useState(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const changePage = pageSel => {
        setPageSelected(pageSel);
        !record && onChangePage('', String(pageSel.pageIndex));
        handleDrawerClose();
    };

    useEffect(() => {
        if (users.length === 0) {
            dispatch(getUsers([], 0, 10000, false, "userCard"));
        }
    }, [dispatch, users]);

    useEffect(() => {
        if (!page) setPageSelected(pages[0]);
        else setPageSelected(page);
        // eslint-disable-next-line
    }, [pages]);

    useEffect(() => {
        const list = [];
        lenguages
            .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
            .forEach(item => {
                list.push({
                    key: item.key,
                    value: item.value
                });
            });
        setLanguageList(list);
    }, [lenguages, secondaryLanguageId, defaultLanguageId]);

    const scaleImageGallery = (maxHeight = 300, maxWidth = 294, image) => {
        const ratio = Math.min(maxWidth / image.width, maxHeight / image.height);
        return {
            width: image.width * ratio,
            height: image.height * ratio,
        };
    };

    const scaleImage = (maxWidth = 294, image) => {
        const aspectRatio = image.width / image.height;
        const imageWidth = Math.min(maxWidth, image.width);
        const imageHeight = imageWidth / aspectRatio;

        return {
            width: imageWidth,
            height: imageHeight,
        };
    };

    const getFormattedText = (text, contentIndex) => {
        let textPreview = document.getElementById("text_preview_result-"+ contentIndex);
        if (textPreview) {
            textPreview.innerHTML = text;
            const percentage = 26 / 100;
            textPreview && Array.from(textPreview.children).forEach(item => {
                if (item.childElementCount === 0) {
                    const fontSize = 16;
                    const newFontSize = fontSize - (fontSize * percentage);
                    item.innerHTML = `<span style="font-size: ${newFontSize}px">${item.innerHTML}</span>`
                } else {
                    Array.from(item.children).forEach(span => {
                        const fontSize = span.style.fontSize.replace("px", "");
                        const newFontSize = fontSize - (fontSize * percentage);
                        span.style.fontSize = `${newFontSize}px`;
                    });
                }
            });
            return textPreview.innerHTML;
        }
        return text;
    };

    return (
        <div style={{
            width: '90%', display: 'flex',
            flexDirection: 'column', alignItems: 'center',
            backgroundColor: 'rgba(10, 162, 192, 0.05)',
            borderRadius: '20px',
            padding: '30px 0 20px 0',
            zIndex: 1,
            height: 'min-content'
        }}>
            <Typography className="preview-head">
                {t("previewLbl")}
            </Typography>
            <div style={{ position: 'relative', width: 350, height: 660 }}>
                <img src={Background} alt="background-logo"
                    style={{
                        width: '100%', height: '100%',
                        background: '#FFF', borderRadius: 50
                    }}
                />
                <div style={{
                    position: 'absolute', top: 50, left: 23,
                    width: 304, height: 575, overflowY: 'scroll'
                }} className='phone-container'>
                    <div style={{ height: 60 }}>
                        <AppBar open={open} style={{
                            position: 'relative',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            display: open ? 'none' : 'flex'
                        }}>
                            <Toolbar style={{ position: 'relative', padding: '0 10px' }}>
                                <IconButton
                                    color="default"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                {system.fileId &&
                                    <div style={{ width: '65%', justifyContent: 'center', display: 'flex' }}>
                                        <img src={getFileURL + system.fileId} alt='logo'
                                            style={{ width: 35, height: 35 }}
                                        />
                                    </div>
                                }
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            sx={{
                                width: 303,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: 303,
                                },
                            }}
                            variant="persistent"
                            anchor="left"
                            open={open}
                            className='menu-preview'
                            style={{ display: open ? 'flex' : 'none' }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginLeft: 10
                            }}>
                                <div style={{ alignItems: 'center' }}>
                                    <img src={getFileURL + system.fileId} alt='logo'
                                        style={{ width: 35, height: 35, marginRight: 10 }}
                                    />
                                    <Typography>{system.name}</Typography>
                                </div>
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </div>
                            <List style={{ marginLeft: 10 }}>
                                <Typography className='menu-preview'>Menu</Typography>
                                {pages.length > 0
                                    ? pages.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemText primary={item.page} className='page-name-preview'
                                                onClick={() => changePage(item)}
                                            />
                                        </ListItem>
                                    ))
                                    : page &&
                                    <ListItem disablePadding>
                                        <ListItemText primary={page.page} className='page-name-preview' />
                                    </ListItem>
                                }
                            </List>
                            <div style={{ marginLeft: 10 }}>
                                <Typography className='menu-preview'>{t("LanguageLbl")}</Typography>
                                <div style={{ marginTop: 25 }}>
                                    {languageList.map((item, index) => (
                                        <label
                                            key={index}
                                            className={`page-name-preview ${item.key !== language && 'selected-idiom'}`}
                                            style={{ marginRight: 25 }} onClick={() => setLanguage(item.key)}
                                        >
                                            {item.value}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </Drawer>
                    </div>
                    {pageSelected && pageSelected.content.map((item, index) => (
                        <div key={index} style={{
                            margin:
                                (item.contentType === 'GALLERY' || item.contentType === 'IMAGE')
                                    ? '0px 2px 20px 2px'
                                    : '0px 20px 20px 20px'
                        }}>
                            {item.contentType === 'TEXT' && item.value[language] &&
                                <div className='text' dangerouslySetInnerHTML={{
                                    __html:
                                        item.value[language] ? getFormattedText(item.value[language].text, item.contentIndex) : null
                                }} id={"text_preview_result-" + item.contentIndex} />
                            }
                            {item.contentType === 'IMAGE' && item.value[language] &&
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}>

                                    <img
                                        src={item.value[language] ? item.value[language].imageUrl : ''}
                                        alt='img' style={{
                                            borderRadius: '4.34px',
                                            ...scaleImage(294, item.value[language])
                                        }}
                                    />
                                </div>
                            }
                            {item.contentType === 'GALLERY' &&
                                <Carousel
                                    autoPlay={false}
                                    infiniteLoop={true}
                                    showThumbs={false}
                                    showArrows={false}
                                    showStatus={false}
                                    style={{ position: "relative" }}
                                >
                                    {item.value[language] && item.value[language].map((image, indexImg) => (
                                        (image.height !== 0 && image.width !== 0) &&
                                        <img
                                            key={indexImg}
                                            src={image.imageUrl}
                                            alt='img' style={{
                                                borderRadius: '4.34px',
                                                ...scaleImageGallery(300, 294, image),
                                                marginBottom: 25
                                            }}
                                        />
                                    ))}
                                </Carousel>
                            }
                            {item.contentType === 'MAP' &&
                                <GoogleMap value={item.value[language] ? item.value[language].map : ''} />
                            }
                            {item.contentType === 'VIDEO' &&
                                //assuming video links are yet only for youtube links.
                                <iframe src={item.value[language] ? item.value[language].url : ''}
                                    width={264}
                                    height={250}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                />
                            }
                            {item.contentType === 'FORM' && item.value[language] &&
                                <div>
                                    {/* <Typography className='label-app' style={{ marginBottom: 10 }}>
                                        {t("contactFormLbl")}
                                    </Typography> */}
                                    {item.value[language].map((itemForm, indexForm) => (
                                        <div key={indexForm}>
                                            <div>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row',
                                                    justifyContent: 'space-between', alignItems: 'center'
                                                }}>
                                                    <InputLabel className="label-form-preview">
                                                        {itemForm.label} <span style={{ color: '#DC3545' }}>{itemForm.required && '*'}</span>
                                                    </InputLabel>
                                                </div>
                                                <TextFieldComponent placeholder={itemForm.placeholder}
                                                    callback={() => null}
                                                    row={itemForm.type === '3' ? 5 : null}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant='contained' className='button-app-save'
                                        style={{ width: '100%', pointerEvents: "none" }}>
                                        {t("submitBtn")}
                                    </Button>
                                </div>
                            }
                            {item.contentType === 'USER_CARD' && users.length > 0 &&
                                item.value[language] && item.value[language].userId &&
                                <>
                                    <div style={{
                                        display: 'flex', marginTop: 18, marginBottom: 32
                                    }}>
                                        <Avatar
                                            className='avatar-user-form'
                                            alt='img'
                                            src={getFileURL + users.find(u => u.id === item.value[language].userId).fileId}
                                            sx={{ width: 51, height: 51 }}
                                        />
                                        <div style={{ marginLeft: 37 }}>
                                            <Typography className="preview-user-name-form">
                                                {users.find(u => u.id === item.value[language].userId).firstName
                                                    + ' ' +
                                                    users.find(u => u.id === item.value[language].userId).lastName
                                                }
                                            </Typography>
                                            <Typography className="preview-job-user-form" style={{ margin: '4px 0px' }}>
                                                {users.find(u => u.id === item.value[language].userId).organizationUser.position}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Typography className="preview-profile-user-form" style={{ margin: '4px 0px' }}>
                                        {users.find(u => u.id === item.value[language].userId).organizationUser.profileDescription}
                                    </Typography>
                                    <Typography className="preview-profile-user-form" style={{ margin: '4px 0px' }}>
                                        {users.find(u => u.id === item.value[language].userId).email}
                                    </Typography>
                                    <br />
                                    <Button variant='contained' className='button-app-save'
                                        style={{ width: '100%', pointerEvents: "none" }}>
                                        {t("importContactLbl")}
                                    </Button>
                                </>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <Typography className='mobile-content-alert' style={{ margin: 20 }}>
                <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                {t("imageAlertLbl")}
            </Typography>
        </div>
    );
}

export default Preview;