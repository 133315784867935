import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Container, Grid, Typography,
    InputLabel, Button, Stepper,
    Step, StepLabel, TableContainer,
    Table, TableHead, TableRow,
    TableCell, TableBody
} from '@mui/material';
import TextFieldComponent from '../../../components/TextField';
import SelectFieldComponent from "../../../components/SelectField";

import { useSelector, useDispatch } from "react-redux";
//import { getDocuments, getFolders } from "../../../redux/DocumentDuck";

import { getOfficesFilter, getBusinessesFilter } from '../../../redux/BusinessDuck';
import { getClients } from '../../../redux/ClientDuck';
import { saveUpdateSubject } from '../../../redux/SubjectDuck';
import * as ROUTES from '../../../constants/routes';
//import { getFileURL } from '../../../constants/apiUrls';
import './Form.css';
import { stepOneValidation } from '../Common/MatterFormValidation';
import RecentDocuments from '../Common/RecentDocuments';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import { ReactComponent as Image } from '../../../assets/Icons/image.svg';
import { ReactComponent as Document } from '../../../assets/Icons/document.svg';
import AccessRowAut from '../Common/AccessRowAut';
import Moment from 'react-moment';
import PaginationCustom from '../../../components/Pagination';

const SubjectForm = props => {
    const { t } = useTranslation(['matter', 'common', 'document']);
    const dispatch = useDispatch();
    //const activePageDoc = useSelector(store => store.doc.activePageDoc);
    //const activePageFolder = useSelector(store => store.doc.activePageFolder);
    const businesses = useSelector(store => store.business.businesses);
    const clientsRedux = useSelector(store => store.client.clients);
    const officesRedux = useSelector(store => store.business.offices);

    const [subject, setSubject] = useState({
        id: '', title: '', description: '', companyId: '', clientId: '', officeId: '',
        filesIds: [], selectedFiles: []
    });
    const isCreating = useSelector(store => store.subject.isCreating);
    const subjectRedux = useSelector(store => store.subject.subject);
    const [error, setError] = useState({});
    const [companies, setCompanies] = useState([]);
    const isCreated = useSelector(store => store.subject.isCreated);
    const [officeId, setOfficeId] = useState('');
    const [offices, setOffices] = useState([]);
    const [companyId, setCompanyId] = useState('');
    const [clientId, setClientId] = useState('');
    const [clients, setClients] = useState([]);
    const [step, setStep] = useState(0);
    const [activePageSelectedDoc, setActivePageSelectedDoc] = useState(0);
    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];
    const obj_per_page = 10;
    //const [resetPageDoc, setResetPageDoc] = useState(false);
    //const [resetPageFolder, setResetPageFolder] = useState(false);

    const totalNumPages = () => {
        return Math.ceil(subject.selectedFiles.length / obj_per_page);
    };

    const changeSelectedDocPage = (page) => {
        setActivePageSelectedDoc(page - 1);
    };

    useEffect(() => {
        setSubject({ ...subject, filesIds: [], selectedFiles: [] });
        // eslint-disable-next-line
    }, [subject.clientId]);

    useEffect(() => {
        (subjectRedux && !isCreating) &&
            setSubject({
                title: subjectRedux.id,
                Description: subjectRedux.firstName,
                companyId: subjectRedux.companyId,
                officeId: subjectRedux.officeId,
                clientId: subjectRedux.clientId,
                filesIds: [],
                clientName: subjectRedux.client.name,
                clientImageId: subjectRedux.client.fileId,
            });
    }, [subjectRedux, isCreating]);

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        if (subject.officeId && officesTemp.find(o => String(o.key) === String(subject.officeId))) {
            setOfficeId(subject.officeId);
        } else {
            setOfficeId('');
        }
        // eslint-disable-next-line
    }, [companyId, officesRedux]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== '' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);

    useEffect(() => {
        if (officeId) {
            dispatch(getClients([
                { search: 'false', filterBy: 'disabled' },
                { search: String(officeId), filterBy: 'office' },
            ], 0, 10000, false, 'subject'));
        }

    }, [dispatch, officeId]);

    useEffect(() => {
        const clientList = [];
        clientsRedux.forEach(item => {
            clientList.push({ key: item.id, value: item.name });
        });
        setClients(clientList);
    }, [clientsRedux]);

    const onChangeCompany = e => {
        setCompanyId(e.target.value);
        setOffices([]);
        setClients([]);
        setSubject({
            ...subject, companyId: e.target.value,
            officeId: '', clientId: ''
        });

    };

    const onChangeOffice = e => {
        setOfficeId(e.target.value);
        setClients([]);
        setSubject({
            ...subject, officeId: e.target.value,
            clientId: ''
        });
    };

    const onChangeClient = e => {
        setClientId(e.target.value);
        setSubject({ ...subject, clientId: e.target.value });
    };

    const onChange = e => {
        const { name, value } = e.target;
        setSubject({ ...subject, [name]: value });
    };


    const onSubmit = () => {
        dispatch(saveUpdateSubject(subject));
    };

    useEffect(() => {
        (isCreated) && props.history.push(ROUTES.MATTERS);
    }, [isCreated, props.history]);

    useEffect(() => {
        setCompanyId(subject.companyId);
    }, [companyId, subject.companyId]);

    useEffect(() => {
        setOfficeId(subject.officeId);
    }, [officeId, subject.officeId]);

    useEffect(() => {
        setClientId(subject.clientId);
    }, [clientId, subject.clientId]);

    const changeStep = (newStep) => {
        if (step < newStep) {
            if (step === 0) {
                const errorOne = stepOneValidation(subject, t);
                setError(errorOne);
                if (Object.entries(errorOne).length === 0) {
                    setStep(newStep);
                }
            } else {
                setStep(newStep);
            }
        } else {
            setStep(newStep);
        }
    };

    return (
        <Container>
            <Grid item xs={12}>
                <div className="step-container">
                    <Stepper activeStep={step} alternativeLabel>
                        <Step key={'label'}>
                            <StepLabel>{t("headerTbl6")}</StepLabel>
                        </Step>
                        <Step key={'label2'}>
                            <StepLabel>{t("addFilesLbl")}</StepLabel>
                        </Step>
                        <Step key={'label3'}>
                            <StepLabel>{t("sendLbl")}</StepLabel>
                        </Step>
                    </Stepper>
                </div>
                {
                    step === 0 &&
                    <>
                        <div className="container-app-div">
                            <Typography className="app-form-title">{t("common:commonFilterLbl")}</Typography>
                            <div style={{
                                display: 'flex', flexDirection: 'row',
                                width: '100%', justifyContent: 'space-between',
                            }}>
                                <div style={{ width: '31%' }}>
                                    <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                                    <SelectFieldComponent name="businessName"
                                        valueInput={companyId}
                                        onChange={onChangeCompany}
                                        options={companies}
                                        errorInput={error.companyId}
                                    />
                                </div>
                                <div style={{ width: '31%' }}>
                                    <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                                    <SelectFieldComponent name="officesName"
                                        valueInput={officeId}
                                        onChange={onChangeOffice}
                                        errorInput={error.officeId}
                                        options={offices}
                                    />
                                </div>
                                <div style={{ width: '31%' }}>
                                    <InputLabel className="label-app">{t("common:commonClientLbl")}</InputLabel>
                                    <SelectFieldComponent name="businessName"

                                        valueInput={clientId}
                                        onChange={onChangeClient}
                                        errorInput={error.clientId}
                                        options={clients}
                                    />
                                </div>
                            </div>
                            <p className='app-error-text'>{error.clientSpecification}</p>
                        </div>
                        <div className="container-app-div">
                            <Typography className="app-form-title">{t("subtitle4")}</Typography>
                            <div style={{ marginBottom: 10 }}>
                                <InputLabel className="label-app">{t("titleLbl")}</InputLabel>
                                <TextFieldComponent name="title" placeholder={t("titlePlh")}
                                    valueInput={subject.title} callback={onChange.bind(this)}
                                    errorInput={error.title}
                                />
                            </div>
                            <InputLabel className="label-app">{t("descriptionLbl")}</InputLabel>
                            <TextFieldComponent name="description" row={4}
                                maxLength={300}
                                placeholder={t("descriptionPlh")}
                                valueInput={subject.description} callback={onChange.bind(this)}
                                errorInput={error.description}
                            />
                        </div>
                    </>
                }
                {
                    step === 1 &&
                    <RecentDocuments
                        subject={subject}
                        setValues={setSubject}
                        error={error}
                    />
                }
                {
                    step === 2 &&
                    <div>
                        <div className="container-app-div">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className="app-form-title">{t("subtitle1")}</Typography>
                                <Button className='button-app-create' variant="text" startIcon={<Edit />}
                                    onClick={() => setStep(0)}>
                                    {t("subtitle1Edit")}
                                </Button>
                            </div>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="header-cell-matters">
                                            <TableCell className="detail-table-cell-notifications" align="right">{t("headerTbl5")}</TableCell>
                                            <TableCell sx={{ width: '78%' }} className="detail-table-cell-notifications">{t("headerTbl6")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={'id1'}>
                                            <TableCell component="th" scope="row" className="description-table-cell-notifications" >
                                                {t("headerTbl7")}
                                            </TableCell>
                                            <TableCell className="detail-table-cell-notifications">
                                                {subject.title}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={'id2'}>
                                            <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                                {t("headerTbl8")}
                                            </TableCell>
                                            <TableCell className="detail-table-cell-notifications">
                                                {subject.description}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={'id3'}>
                                            <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                                {t("common:commonCompanyLbl")}
                                            </TableCell>
                                            <TableCell className="detail-table-cell-notifications">
                                                {businesses.length > 0 && businesses.find(b => String(b.id) === String(subject.companyId)).name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={'id4'}>
                                            <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                                {t("common:commonOfficeLbl")}
                                            </TableCell>
                                            <TableCell className="detail-table-cell-notifications">
                                                {officesRedux.length > 0 && officesRedux.find(o => String(o.id) === String(subject.officeId)).name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={'id5'}>
                                            <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                                {t("common:commonClientLbl")}
                                            </TableCell>
                                            <TableCell className="detail-table-cell-notifications">
                                                {clientsRedux.length > 0 && clientsRedux.find(c => String(c.id) === String(subject.clientId)).name}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className="container-app-div">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className="app-form-title">{t("selectedFilesLbl")} </Typography>
                                <Button className='button-app-create' variant="text" startIcon={<Edit />}
                                    onClick={() => setStep(1)}>
                                    {t("manageFilesLbl")}
                                </Button>
                            </div>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='table-app-title-header'>{t("common:commonNameLbl")}</TableCell>
                                            <TableCell className='table-app-title-header'>{t("common:commonFolderLbl")}</TableCell>
                                            <TableCell className='table-app-title-header'>{t("common:commonCreatedByLbl")}</TableCell>
                                            <TableCell className='table-app-title-header'>{t("common:commonTypeLbl")}</TableCell>
                                            <TableCell className='table-app-title-header'>{t("common:commonDateLbl")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subject.selectedFiles.length === 0 &&
                                            <TableRow key='empty'>
                                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                                    {t("selectedFilesLbl")}
                                                </TableCell>
                                            </TableRow>
                                        }
                                        {subject.selectedFiles.map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <AccessRowAut
                                                            title={item.fileName}
                                                            Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                                ? Image : Document}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row"  >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Folder style={{ marginRight: '10.14px' }} />
                                                        <Typography className='folder-title-cel-text'>
                                                            {item.folder.name}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                                    <div style={{ width: "160px", textOverflow: "ellipsis", overflow: "hidden" }}>
                                                        {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                                    <div style={{ width: "160px", textOverflow: "ellipsis", overflow: "hidden" }}>
                                                        {item.fileExtension.replace(".", "").toUpperCase()}
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                                    <div>
                                                        <Moment className="app-form-subtitle" style={{ marginTop: '4px' }}
                                                            format="DD/MM/YYYY">
                                                            {item.created}
                                                        </Moment>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div style={{ marginTop: 24 }}>
                                <PaginationCustom pages={totalNumPages()} activePage={activePageSelectedDoc}
                                    onChange={page => changeSelectedDocPage(page)} />
                            </div>
                        </div>
                    </div>
                }
                <div style={{ display: 'flex' }}>
                    {step > 0 &&
                        <Button variant="contained" className="button-app-previous" style={{ marginRight: 24 }}
                            onClick={() => changeStep(step - 1)}>
                            {t("common:commonPreviousLbl")}
                        </Button>
                    }
                    {step < 2
                        ?
                        <Button variant="contained" className="button-app-save"
                            onClick={() => changeStep(step + 1)}>
                            {t("common:commonNextLbl")}
                        </Button>
                        :
                        <Button variant="contained" className="button-app-save"
                            onClick={() => onSubmit()}>
                            {t("common:commonSubmitBtn")}
                        </Button>
                    }
                </div>
            </Grid>
        </Container>
    )
};

export default withRouter(SubjectForm);