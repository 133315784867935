export const stepOneValidation = (subject,t) => {
    var error = {};
    //const onlyLetters = /^[a-zñáéíóúüñ]*$/i;
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;
    if (subject.title === null || subject.title.length === 0 ||
        /^\s+$/.test(subject.title))
        error.title = t("errorTitle");
    if (subject.title.length > 50)
        error.title = t("errorMax50");

    if (subject.description === null || subject.description.length === 0 ||
        /^\s+$/.test(subject.description))
        error.description =t("errorDescription") ;
    if (subject.description.length > 300)
        error.description = t("errorMax300");
    else
        if (!subject.title.replaceAll(" ", "").match(onlyLetters))
            error.title = t("errorAlphabet");

        else
            if (!subject.description.replaceAll(" ", "").match(onlyLetters))
                error.description = t("errorAlphabetLong");

    if (subject.companyId === null || subject.companyId.length === 0 ||
        /^\s+$/.test(subject.companyId))
        error.companyId = t("errorCompany");

    if (subject.officeId === null || subject.officeId.length === 0 ||
        /^\s+$/.test(subject.officeId))
        error.officeId = t("errorOffice");

    if (subject.clientId === null || subject.clientId.length === 0 ||
        /^\s+$/.test(subject.clientId))
        error.clientId = t("errorClient");

    return error;
};
