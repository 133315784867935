import React, { useState, useEffect, createRef, useRef } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
    Container, Typography, Button,
    Grid, InputLabel, Avatar
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import TextFieldComponent from "../../../components/TextField";
//import SelectFieldComponent from "../../../components/SelectField";
import Title from "../../../components/Title";
import ChangePassword from '../../ChangePassword';
import { getFileURL } from '../../../constants/apiUrls';
import {
    TableContainer, TableRow,
    TableCell, Table, TableBody, Accordion, AccordionDetails, AccordionSummary, Alert,
} from "@mui/material";
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as Printer } from '../../../assets/Icons/printer.svg';
import { updateUser } from '../../../redux/UserDuck';
//import { changePasswordAction } from '../../../redux/AuthDuck'
import { getBusinessById } from '../../../redux/BusinessDuck'
import { getSubscriptionByOrgId, getPaymentsBySubscriptionId, changeSubscriptionPlan, changePaymentMethod, cancelSubscription, changePaymentPage } from '../../../redux/SubscriptionDuck'
import { getUserById } from '../../../redux/UserDuck'
import Loading from '../../../components/Loading';
import { userValidation, cardChangeValidation } from './FormValidation';
import { getCompanyById, getAdmin } from '../../../redux/ClientDuck';
import ChangePaymentMethodModal from '../../../components/DeleteMessage';
import ChangePlanModal from '../../../components/DeleteMessage';
import PaginationCustom from "../../../components/Pagination";

import CancelPlanModal from '../../../components/DeleteMessage';
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import PaymentForm from "./PaymentForm";
import '../userProfile.css';

const Read = props => {
    const { t } = useTranslation(['userProfile', 'common', 'subscription', 'validations', 'userProfile']);
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const company = useSelector(store => store.client.company);
    const companyAdmin = useSelector(store => store.client.companyAdmin);
    const userRedux = useSelector(store => store.user.user);
    const activePage = useSelector(store => store.subscription.activePage);
    const pages = useSelector(store => store.subscription.pages);

    const client = useSelector(store => store.business.business);
    const countries = useSelector(store => store.country.countries);
    const checkoutError = useSelector(store => store.subscription.alertMessage);
    const payments = useSelector(store => store.subscription.payments);
    const subscription = useSelector(store => store.subscription.subscription);
    const [printed, setPrinted] = useState(false);
    const [currentPlan, setCurrentPlan] = useState("");
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [card, setCard] = useState("");
    const [cardBrand, setCardBrand] = useState("");
    const [monthlyBilling, setMonthlyBilling] = useState(true);
    const [planChangeVisible, setPlanChangeVisible] = useState(false);
    const [paymentChangeVisible, setPaymentChangeVisible] = useState(false);
    const [openPlanModal, setOpenPlanModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [resetPage, setResetPage] = useState(false);

    const [PaymentRequest, setPaymentRequest] = useState({
        id: '', firstName: '', lastName: '', email: '',
        countryCode: '', phoneNumber: '', position: '',
        profileDescription: '', image: '', imageUrl: '',});

        const [openPaymentModal, setOpenPaymentModal] = useState(false);
        const [user, setUser] = useState({
            id: '', firstName: '', lastName: '', email: '',
            countryCode: '', phoneNumber: '', position: '',
            profileDescription: '', image: '', imageUrl: '', isAdmin: null

        });
        const [error, setError] = useState({});
        const [cardError, setCardError] = useState({});
        const imgRef = createRef();
        const [form, setForm] = useState({
            cardHolderName: '',
            cardN: '',
            exp: '',
            cvv: '',
            planName: '',

        });
        const [payment, setPayment] = useState({
            cardHolderName: '',
            cardN: '',
            exp: '',
            cvv: '',
            planName: '',
        });
        const [paymentError, setPaymentError] = useState({});
        const [activeSubscription, setActiveSubscription] = useState(true);

        const allComponentRef = useRef();
        const componentRef = useRef();

        const pageStyle = `
    @page {
      size: auto;
      margin: 20mm 1mm;
    }

    @media print {
        html, body {
          background-color: #FFFFFF !important;
        }
      }
  `;

        const handlePrint = useReactToPrint({

            content: () => componentRef.current,
            documentTitle: company,
            pageStyle: pageStyle,
        });

        const print = () => {
            //getLogsId();
            const timer = setTimeout(() => {
                const printbutton = document.getElementsByClassName("button-print-download");
                hidePrintButtons(printbutton, "none")
                handlePrint();
                hidePrintButtons(printbutton, "flex")

                //document.getElementsByClassName("button-print-download").style.display = "flex";
                setPrinted(true);
            }, 1000);
            return () => clearTimeout(timer);
        };
        const hidePrintButtons = (elements, displayStyle) => {
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.display = displayStyle;
            }
        };
        useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem('user-fos'));
    dispatch(getUserById(currentUser.id));
    dispatch(getSubscriptionByOrgId(currentUser.organizationId));
    dispatch(getBusinessById(currentUser.organizationId));
    dispatch(getCompanyById(currentUser.organizationId));
    dispatch(getAdmin(currentUser.organizationId));
    dispatch(getPaymentsBySubscriptionId(
        [{ search: "false", filterBy: "disabled" }],
        0, 10, false, ""
    ));

    userRedux && console.log("is admin?", user && user.isAdmin, userRedux);
    // console.log(localStorage.getItem('user-fos')(id));
    //    console.log("aesjs", aesjs.utils.utf8.toBytes("all random text is a blessing in disguise"));

}, [dispatch]);

useEffect(() => {
    const page = resetPage ? 0 : activePage;
    //const filterBy = [];
    //search !== '' && filterBy.push({ search: "false", filterBy: "disabled" });
    //filter !== 'none' && filterBy.push({ search: "false", filterBy: "disabled" });
    dispatch(getPaymentsBySubscriptionId([{ search: "false", filterBy: "disabled" }], page, 10, false, ""));
    setResetPage(false);
    // eslint-disable-next-line
}, [dispatch, activePage]);

useEffect(() => {
    if (userRedux) {
        setUser({
            id: userRedux.id,
            firstName: userRedux.firstName,
            lastName: userRedux.lastName,
            email: userRedux.email,
            countryCode: userRedux.countryCodeId,
            phoneNumber: userRedux.phone,
            position: userRedux.organizationUser.position,
            profileDescription: userRedux.organizationUser.profileDescription,
            imageUrl: userRedux.fileId ? getFileURL + userRedux.fileId : '',
            image: ''
        });
    }
}, [userRedux]);
useEffect(() => {
    //  payments && console.log("payments", payments)
}, [payments]);
useEffect(() => {
    company && console.log("XX", "company", company)
}, [company]);


    useEffect(() => {
        setCard(subscription.cardLast4Digits);
        setCardBrand(subscription.paymentProcessor);
        setCurrentPlan(subscription.subscriptionPlanId ? subscription.subscriptionPlanId - 1 : 2);
        setMonthlyBilling(subscription.billingPeriod === "MONTHLY" ? true : false);
    }, [subscription, dispatch]);

    const onSubmitUserInfoChange = () => {
        const userError = userValidation(user, imgRef, t);
        setError(userError);
        if (Object.entries(userError).length === 0) {
            dispatch(updateUser(user, null));
        }
    };

    const onClickPlanSwitch = (planId, t) => {
        setSelectedPlan(planId);
        setOpenPlanModal(true);
    };
    const onSubmitPlanChange = () => {
        dispatch(changeSubscriptionPlan(selectedPlan, subscription.greenpaySubscriptionId, company.id));

    };

    const onSubmitPaymentMethodChange = () => {
        const paymentFormError = cardChangeValidation(form, t);
        setCardError(paymentFormError);
        if (Object.entries(paymentFormError).length === 0) {
            dispatch(changePaymentMethod(form, subscription.greenpaySubscriptionId));
        }
    };

    const onSubmitCancelSubscription = () => {
        dispatch(cancelSubscription(subscription.greenpaySubscriptionId));
    };

    const onChange = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const onChangeForm = e => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const onChangePayment = e => {
        const { name, value } = e.target;
        setPayment({ ...form, [name]: value });
    };

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const file = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                if (img.width <= 400 && img.height <= 200) {
                    setUser({
                        ...user,
                        image: file,
                        imageUrl: URL.createObjectURL(file)
                    });
                    setError({ ...error, image: '' });
                } else {
                    setError({
                        ...error, image: t("common:commonNoValidDimensions") + `
                    (${img.width} x ${img.height}),` + t("textAllowed") + ` :  (400 x 200)`
                    });
                }
            }
        }
    };

    const expandPlanAccordion = (value) => {
        setPlanChangeVisible(true);
        document.querySelector('#subscription-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    };

    const expandPaymentAccordion = (value) => {
        setPaymentChangeVisible(true);
        document.querySelector('#paymentMethod-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    };

    const status = [
        { status: t("common:commonStatusProcessing"), color: '#6C757D' },
        { status: t("common:commonStatusActive"), color: '#28A745' },
        { status: t("common:commonStatusArchived"), color: '#6C757D' },
    ];

    const colors = [
        { "band-color": "#16acea", "title-color": "#fff" },
        { "band-color": "#5ab6df", "title-color": "#fff" },
        { "band-color": "#bee3e9", "title-color": "#959393" },
        { "band-color": "#aba3d0", "title-color": "#fff" },
        { "band-color": "#8976b6", "title-color": "#fff" },
        { "band-color": "#fff", "title-color": "#fff" }
    ];

    const mockplans = [
        {
            "id": 1,
            "name": "Startup",
            "cantidad_de_usuarios": 5,
            "costo_por_usuario_plan_mensual": 29,
            "costo_por_usuario_plan_anual": 29,
            "total_plan_mensual": 145,
            "total_plan_anual": 1450
        },
        {
            "id": 2,
            "name": "Basic",
            "cantidad_de_usuarios": 10,
            "costo_por_usuario_plan_mensual": 27,
            "costo_por_usuario_plan_anual": 27,
            "total_plan_mensual": 270,
            "total_plan_anual": 2700
        },
        {
            "id": 3,
            "name": "Standard",
            "cantidad_de_usuarios": 25,
            "costo_por_usuario_plan_mensual": 25,
            "costo_por_usuario_plan_anual": 25,
            "total_plan_mensual": 625,
            "total_plan_anual": 6250
        },
        {
            "id": 4,
            "name": "Corporate",
            "cantidad_de_usuarios": 50,
            "costo_por_usuario_plan_mensual": 23,
            "costo_por_usuario_plan_anual": 23,
            "total_plan_mensual": 1150,
            "total_plan_anual": 11150
        },
    ];

    return (
        <Container>
            <ChangePlanModal
                open={openPlanModal}
                closeModal={() => setOpenPlanModal(false)}
                handleClick={() => onSubmitPlanChange()}
                buttonMessage={t("changePlanLbl")}
                textMessage={t("changePlanConfirmationLbl")}
            />
            <ChangePaymentMethodModal
                open={openPaymentModal}
                closeModal={() => setOpenPaymentModal(false)}
                handleClick={() => onSubmitPaymentMethodChange()}
                buttonMessage={t("changePaymentMethodLbl")}
                textMessage={t("changePaymentMethodConfirmationLbl")}
            />
            <CancelPlanModal
                open={openCancelModal}
                closeModal={() => setOpenCancelModal(false)}
                handleClick={() => onSubmitCancelSubscription()}
                buttonMessage={t("cancelSubscriptionLbl")}
                textMessage={t("cancelSubscriptionConfirmationLbl")}
            />
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div" style={{ display: "flex", alignItems: "flex-end" }}>
                    <ChangePassword>
                        {(userRedux) &&
                            <div className="Options-container" style={{ display: 'flex', marginBottom: '35px' }}>
                                <div style={{
                                    width: '50%',
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',

                                }}>
                                    <Button component="label" style={{
                                        background: 'transparent',
                                        textTransform: 'capitalize'
                                    }}>
                                        <input type="file" accept="image/*"
                                            name="image" onChange={onImageChange}
                                            style={{ display: "none" }}
                                        />
                                        <p style={{ position: 'absolute', color: "white", top: "60px", zIndex: "2", fontSize: "11px", textShadow: "2px 2px 6px #000000" }}>{t("common:commonChangePicLbl")}</p>
                                        <Avatar
                                            className='avatar-user-form' alt='Photo'
                                            src={user.imageUrl}
                                            sx={{ width: 112, height: 112 }}
                                            ref={imgRef}
                                        />
                                    </Button>
                                    <div style={{ marginLeft: 37 }}>
                                        <Typography className="user-name-form">
                                            {userRedux.firstName + ' ' + userRedux.lastName}
                                        </Typography>
                                        <Typography className="job-user-form" style={{ margin: '4px 0px' }}>
                                            {userRedux.position}
                                        </Typography>
                                        <p className='app-error-text'>{error.image}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </ChangePassword>
                    <div style={{ width: '50%' }}>
                        {userRedux &&
                            <div>
                                <InputLabel className="label-app">{t('userProfile:changeEmailLbl')}</InputLabel>
                                <div className="emailInput" style={{ width: '85%' }}>
                                    <TextFieldComponent name="email"
                                        valueInput={user.email}
                                        callback={onChange}
                                        errorInput={error.email}
                                    />
                                </div>
                                <Button className="button-app-save" onClick={onSubmitUserInfoChange}>
                                    {t('userProfile:changeLbl')}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("headerTbl4")}</Typography>
                    {(userRedux) && (company) &&
                        <TableContainer>
                            <Table aria-label="simple table" style={{ padding: '0px !important', borderTop: '1px solid #ddd' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("Description")} </span> <span> {t("Detail")}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("ClientLbl")} </span> <span>    {company.name}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("StatusLbl")} </span> <span>  <div style={{
                                                    display: 'flex', alignItems: 'center', color: status[userRedux.status].color, margin: '0px'
                                                }}>
                                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                                    <Typography>
                                                        {status[userRedux.status].status}
                                                    </Typography>
                                                </div></span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("AdminLbl")} </span> <span>  {companyAdmin ?? t("NoAdminLbl")}
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name mb1' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("CreatedOnLbl")} </span> <span>
                                                    <Moment style={{ marginTop: '4px' }}
                                                        format="DD MMMM YYYY">
                                                        {company.created}
                                                    </Moment>
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
                {
                    userRedux && userRedux.isAdmin &&
                    <div>
                        <Title title={t("userProfile:manageSubscription")} style={{ marginLeft: 5 }} />
                        <div className="container-app-div managePlan">
                            <Typography className="app-form-title">{t("LicensingTitleLbl")}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="mb1" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="cell-info licenses">
                                        <span> {t("LicensesLbl")} </span> <span> {company.numberOfLicense}</span>
                                    </div>
                                    <div className="cell-info licenses">
                                        <span> {t("UsersLbl")} </span> <span> {company.usersCount} </span>
                                    </div>
                                    <div className="cell-info licenses">
                                        <span> {t("LicensesLeftLbl")} </span> <span> {company.numberOfLicense - company.usersCount} </span>
                                    </div>

                                    
                                    <div className="cell-info licenses">
                                        <span> {t("PlanTypeLbl")} </span> <span> {monthlyBilling ? t("monthly") : t("annual")} </span>

                                    </div>
                                    {

                                        (!planChangeVisible) &&
                                        <div className="plan-warning">
                                            {t("licenseChangeAlertLbl")}

                                        </div>
                                    }

                                </div>
                            </div>
                            {

                                (!planChangeVisible) &&
                                <Button variant="contained" className="button-app-save"
                                    onClick={() => expandPlanAccordion(0)} style={{ marginRight: 18 }}>
                                    {t("licenseChangeLbl")}
                                </Button>
                            }
                            <Typography style={{ display: 'none' }} className="app-form-subtitle">
                                {t("changeEmailNoticeLbl")}
                            </Typography>
                            <Accordion className="accordion-sumary-row-title" id="subscription-accordion"
                                expanded={planChangeVisible} ref={inputRef}>
                                <AccordionSummary style={{ display: 'none' }} />
                                <AccordionDetails>
                                    <div className="cards-container profile">
                                        {

                                            (planChangeVisible) &&
                                            <div className="plan-warning">
                                                {t("licenseChangeAlertLbl")}

                                            </div>
                                        }
                                        <div className='reg-div cards profile' >

                                            {(mockplans) && Array.isArray(mockplans) &&
                                                mockplans.map((row, index) => (
                                                    <div className={index === currentPlan ? "reg-card-container current " : "reg-card-container " + (row.cantidad_de_usuarios - company.usersCount < 1 ? "disabled" : "")} key={index}>
                                                        <div className="reg-card">
                                                            <div className='plan-title' style={{ backgroundColor: colors[index]["band-color"] }}>
                                                                <div className="plan-label-app" style={{ fontSize: 'inherit', color: colors[index]["title-color"] }}>{row.name}</div>
                                                            </div>
                                                            <div className="plan-prices">
                                                                <div className='perUserCost'>${monthlyBilling ? row.costo_por_usuario_plan_mensual : row.costo_por_usuario_plan_anual}<span>/user</span></div>
                                                                <div className='maxUsers'>up to {row.cantidad_de_usuarios} users </div>
                                                                <div> Unlimited mobile users </div>
                                                                {
                                                                    index !== 4
                                                                        ? <div className='totalMonthly'>
                                                                            Total {monthlyBilling
                                                                                ? "Monthly"
                                                                                : "Annual"}
                                                                            : ${monthlyBilling
                                                                                ? row.total_plan_mensual.toLocaleString("en-us")
                                                                                : row.total_plan_anual.toLocaleString("en-us")}
                                                                        </div>
                                                                        : <div className='totalMonthly'> Contact Us </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            (row.cantidad_de_usuarios - company.usersCount > 0 && index !== currentPlan) &&
                                                            <Button variant="contained" value={index} className="button-app-save" onClick={() => onClickPlanSwitch(row.id)} >
                                                                <span>{t("switchPlanLbl")}  </span>
                                                            </Button>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                        </div>
                        {(subscription.greenpaySubscriptionId) ?
                            <div className="container-app-div paymentMethod">
                                <Typography className="app-form-subtitle">{t("paymentMethodLbl")} </Typography>
                                <span className="paymentMethod" style={{ marginLeft: '25px' }}>
                                    {cardBrand + " ending in " + card}
                                </span>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignContent: 'flex-start',
                                    marginTop: '20px'
                                }}>
                                    {!paymentChangeVisible &&
                                        <Button variant="contained" className="button-app-save"
                                            style={{ marginRight: 18 }} onClick={() => expandPaymentAccordion(0)}>
                                            {t("changePaymentMethodLbl")}
                                        </Button>
                                    }
                                    <Accordion className="accordion-sumary-row-title" id="paymentMethod-accordion"
                                        expanded={paymentChangeVisible} ref={inputRef} >
                                        <AccordionSummary style={{ display: 'none' }} />
                                        <AccordionDetails>
                                            <div style={{ width: 350 }}>
                                                <PaymentForm
                                                    form={form}
                                                    error={cardError}
                                                    onChangeForm={onChangeForm}
                                                />
                                                {checkoutError &&
                                                    <Alert severity="error" className='change-pass-alert'>
                                                        {t(checkoutError)}
                                                    </Alert>
                                                }
                                                <Loading />
                                                <Button variant="contained" className="button-app-save"
                                                    style={{ marginTop: 20 }}
                                                    onClick={() => setOpenPaymentModal(true)}>
                                                    {t("confirmPaymentMethodChange")}
                                                </Button>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            :
                            <div className="container-app-div paymentMethod">
                                <Typography className="app-form-subtitle">{t("paymentMethodLbl")}</Typography>
                                <Alert severity="error" className='change-pass-alert'>{t("NoSubscription")}</Alert>
                            </div>
                        }
                        {
                            (payments) ?
                                <div className="container-app-div paymentHistory" >

                                    <Typography className="app-form-title">{t("paymentHistoryLbl")} </Typography>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        marginBottom: '15px'
                                    }} ref={allComponentRef}>

                                        <div className="historyRow headers" >
                                            <div className="historyColumn">{t("paymentDate")} </div>
                                            <div className="historyColumn">{t("paymentTotal")} </div>
                                            <div className="historyColumn">{t("paymentDescription")} </div>
                                            <div className="historyColumn">{t("printLbl")} </div>
                                        </div>
                                        {(payments) && Array.isArray(payments) &&
                                            payments.map((row, index) => (

                                                <div className="historyRow" data-index={index} ref={componentRef}>
                                                    <div className="historyColumn">{new Date(row.dateOfCharge).toLocaleDateString()}</div>
                                                    <div className="historyColumn">${row.chargeAmount}</div>
                                                    <div className="historyColumn">{row.cardLast4Digits}</div>
                                                    <Button className="button-print-download" variant="text" startIcon={<Printer />}
                                                        style={{ marginRight: 24, float: 'right' }} onClick={() => print()}>

                                                    </Button>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <PaginationCustom pages={pages} activePage={activePage}
                                        onChange={page => dispatch(changePaymentPage(page))} />


                                    <div style={{ display: 'flex', marginTop: 19 }} id="container-print-download">

                                    </div>
                                </div>
                                :
                                <div className="container-app-div paymentMethod">
                                    <Typography className="app-form-subtitle">{t("paymentHistoryLbl")} </Typography>
                                    <Alert severity="error" className='change-pass-alert'> {t("NoSubscription")}</Alert>

                                </div>
                        }
                        <div className="container-app-div">
                            <Typography className="app-form-title">
                                {activeSubscription
                                    ? t("cancelSubscriptionLbl")
                                    : t("reactivateSubscriptionLbl")
                                }
                            </Typography>
                            {!activeSubscription &&
                                <div style={{ width: 350 }}>
                                    <PaymentForm
                                        form={payment}
                                        error={paymentError}
                                        onChangeForm={onChangePayment}
                                    />
                                </div>
                            }
                            <div style={{ display: 'flex' }}>
                                <Button variant="contained" onClick={() => setOpenCancelModal(true)}
                                    style={{ marginTop: activeSubscription ? 0 : 20 }}
                                    className={activeSubscription ? "button-app-delete" : "button-app-save"}>
                                    {activeSubscription
                                        ? t("cancelSubscriptionBtn")
                                        : t("reactivateSubscriptionBtn")
                                    }
                                </Button>
                            </div>
                        </div>

                    </div>

                }

            </Grid>
        </Container>
    );
}

export default withRouter(Read);