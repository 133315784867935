import React, { useState, useCallback, useEffect } from "react";
import {
    Typography, TableContainer,
    TableRow, TableCell, Table, TableBody, TableHead, Button
} from "@mui/material";
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import PaginationCustom from "../../../components/Pagination";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const ThirdStep = ({ setStep, notification }) => {
    const { t } = useTranslation(['notification', 'common', 'label']);

    const businesses = useSelector(store => store.business.businesses);
    const clients = useSelector(store => store.client.clients);
    const offices = useSelector(store => store.business.offices);
    const [activePageReceivers, setActivePageReceivers] = useState(0);
    const [receivers, setReceivers] = useState([]);

    const obj_per_page = 10;

    const totalRecNumPages = () => {
        return Math.ceil(notification.receivers.length / obj_per_page);
    };

    const changeReceiversPage = (page) => {
        setActivePageReceivers(page - 1);
    };

    const drawRowReceivers = useCallback((page, receivers) => {
        const list = [];
        for (let index = page * obj_per_page; index < (page * obj_per_page) + obj_per_page; index++) {
            if (receivers.length > index) {
                list.push(receivers[index]);
            }
        }
        setReceivers(prev => ([...list]));
    }, []);

    const getClientsName = (clients) => {
        const list = [];
        clients.forEach(item => {
            !list.includes(item.name) && list.push(item.name);
        });
        return list.toString().replace(',', ', ');
    };

    useEffect(() => {
        drawRowReceivers(activePageReceivers, notification.receivers);
        // eslint-disable-next-line 
    }, [drawRowReceivers, activePageReceivers, notification.receivers.length]);

    return (
        <div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle4")}</Typography>
                    <Button className='button-app-create' variant="text" startIcon={<Edit />}
                        onClick={() => setStep(0)}>
                        {t("editDetailsBtn")}
                    </Button>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow className="header-cell-matters">
                                <TableCell className="detail-table-cell-notifications" align="right">{t("headerTbl8")}</TableCell>
                                <TableCell sx={{ width: '78%' }} className="detail-table-cell-notifications">{t("headerTbl9")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={'id'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications" >
                                    {t("headerTbl10")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {notification.title}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id2'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl8")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {notification.description}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id3'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl12")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {businesses.length > 0 && businesses.find(b => String(b.id) === String(notification.companyId)).name}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id4'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl13")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {offices.length > 0 && offices.find(o => String(o.id) === String(notification.officeId)).name}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id5'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl14")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {clients.length > 0 && notification.clientId !== 'all' ?
                                        clients.find(c => String(c.id) === String(notification.clientId)).name
                                        : t('label:allsLbl')
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle6")}</Typography>
                    <Button className='button-app-create' variant="text" startIcon={<Edit />}
                        onClick={() => setStep(1)}>
                        {t("editRecipientsBtn")}
                    </Button>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-app-title-header'>{t("headerTbl5")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl6")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl7")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receivers.map(item => (
                                <TableRow key={item.id} >
                                    <TableCell component="th" scope="row" className='notifications-text-active-clients'>
                                        {item.firstName} {item.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='notifications-text-active-clients'>
                                        {item.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='notifications-text-active-clients'>
                                        {item.clients ? getClientsName(item.clients) : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ marginTop: 24 }}>
                    <PaginationCustom pages={totalRecNumPages()} activePage={activePageReceivers}
                        onChange={page => changeReceiversPage(page)} />
                </div>
            </div>
        </div>
    );

}

export default (ThirdStep);