import React from 'react';
import TextFieldComponent from '../../../components/TextField';
import { InputLabel } from '@mui/material';
import "./PaymentForm.css";

const PaymentForm = ({ form, onChangeForm, error }) => {

    const minDate = () => {
        const actualDate = new Date().toISOString().split("T")[0];
        const splitDate = actualDate.split("-", 2)
        return splitDate[0] + "-" + splitDate[1];
    };

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%" }}>
            <InputLabel className="label-app">
                Name on Card
            </InputLabel>
            <TextFieldComponent name="cardHolderName"
                type="text"
                valueInput={form.cardHolderName}
                callback={onChangeForm}
                errorInput={error.cardHolderName}
            />
            <InputLabel className="label-app">
                Card Number
            </InputLabel>
            <TextFieldComponent name="cardN"
                type="text"
                valueInput={form.cardN}
                callback={onChangeForm}
                errorInput={error.cardN}
            />
            <InputLabel className="label-app">
                CVV
            </InputLabel>
            <TextFieldComponent name="cvv"
                type="text"
                valueInput={form.cvv}
                callback={onChangeForm}
                errorInput={error.cvv}
            />
            <InputLabel className="label-app">
                Expiration Date
            </InputLabel>
            <TextFieldComponent
                name="exp"
                type="month"
                valueInput={form.exp}
                min={minDate()}
                callback={onChangeForm}
                errorInput={error.exp}
            />
        </div>
    )
};

export default PaymentForm;