
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
    TableContainer, Table, TableHead, TableCell,
    TableRow, TableBody, Button, Modal, Typography,
    Box, Tab
} from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import { ReactComponent as Spam } from '../../../../assets/Icons/spam.svg';
// import { ReactComponent as CheckCircle } from '../../../../assets/Icons/checkCircle.svg';
import { ReactComponent as CloseCross } from '../../../../assets/Icons/closeCross.svg';
import { ReactComponent as User } from '../../../../assets/Icons/user.svg';
import { ReactComponent as BriefCase } from '../../../../assets/Icons/briefcase.svg';
import { ReactComponent as Email } from '../../../../assets/Icons/email.svg';
import TableClientAssigment from '../TableClientAssigments';
import TextFieldComponent from "../../../../components/TextField";
import { ReactComponent as Search } from '../../../../assets/Icons/search.svg';
import { useDispatch, useSelector } from "react-redux";
import { /*getClientStaff,*/ getClientsOfClientStaff, changeClientPage } from "../../../../redux/UserAssignDuck";
import PaginationCustom from "../../../../components/Pagination";
// import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
const TableUserAssigned = ({ userList = [] }) => {

    const { t } = useTranslation(['matter', 'common', 'customerStaff']);
    const clientStaff = useSelector(store => store.assign.clientStaff);
    const activePageClient = useSelector(store => store.assign.activePageClient);
    const pagesClient = useSelector(store => store.assign.pagesClient);
    const clients = useSelector(store => store.client.clients);
    const countries = useSelector(store => store.country.countriesOriginal);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('1');
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeClientPage(1));
        //component will unmount
        return () => {
            dispatch(changeClientPage(1));
        }
    }, [dispatch]);

    // const handleOpen = (userId) => {
    //     dispatch(getClientStaff(userId));
    //     setOpen(true);
    // };
    const handleClose = () => setOpen(false);

    const handleChange = (value, newValue) => {
        setValue(newValue);
    };

    // const getClientsName = (clients) => {
    //     const list = [];
    //     clients.forEach(item => {
    //         !list.includes(item.name) && list.push(item.name);
    //     });
    //     return list.toString().replace(',', ', ');
    // };

    const getStaffClientName = (clientsList) => {
        const list = [];
        clientsList.forEach(item => {
            const client = clients.find(cli => cli.id === item.clientId);
            (client && !list.includes(client.name)) && list.push(client.name);
        });
        return list.toString().replace(',', ', ');
    };

    useEffect(() => {
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        const timer = setTimeout(() => dispatch(getClientsOfClientStaff(filterBy, activePageClient)), 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, search]);

    useEffect(() => {
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        dispatch(getClientsOfClientStaff(filterBy, activePageClient));
        // eslint-disable-next-line
    }, [dispatch, activePageClient]);

    const onChange = (e) => {
        setSearch(e.target.value);
    };

    // const status = [
    //     { status: t("common:commonStatusProcessing"), color: '#6C757D' },
    //     { status: t("common:commonStatusActive"), color: '#28A745' },
    //     { status: t("common:commonStatusArchived"), color: '#6C757D' },
    // ];

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='table-app-title-header'>{t("common:commonNameLbl")}</TableCell>
                        <TableCell className='table-app-title-header'>Email</TableCell>
                        <TableCell className='table-app-title-header'>{t("common:commonPhoneLbl")}</TableCell>
                        {/* <TableCell className='table-app-title-header'>{t("common:commonClientLbl")}</TableCell> */}
                        {/* <TableCell className='table-app-title-header'>{t("common:commonStatusLbl")}</TableCell> */}
                        {/* <TableCell className='table-app-title-header' align="right">{t("common:commonActionsLbl")}</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                {t("common:commonNoResultsLbl")}
                            </TableCell>
                        </TableRow>
                    }
                    {userList.map(item => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.firstName} {item.lastName}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.email}
                            </TableCell>
                            {/* <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.clients ? getClientsName(item.clients) : ''}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                <div className='table-row-cell-user' style={{ color: status[item.status].color }}>
                                    <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                    <Typography>
                                        {status[item.status].status}
                                    </Typography>
                                </div>
                            </TableCell> 
                            <TableCell align="right">
                                <Button className='button-app-see-detail'
                                    onClick={() => handleOpen(item.id)}>
                                    {t("common:commonSeeLbl")}
                                </Button>
                            </TableCell> */}
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.countryCodeId && `${'(' +
                                    (countries.find(country => country.key === item.countryCodeId)
                                        ? countries.find(country => country.key === item.countryCodeId).code
                                        : '')
                                    + ')'}`} {item.phone}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div className="container-div-modal">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="modal-title-text">{t("common:commonActionsLbl")}</Typography>
                            <Button className='button-close-cross' variant="text" onClick={handleClose} startIcon={<CloseCross />}></Button>
                        </div>
                        <div>

                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example">
                                        <Tab label="Datos del Usuario" value="1" />
                                        <Tab label="Asignación de Clientes" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='tab-select-page-assigments-data-user'>
                                    <Typography className="subtitle-data-client" style={{ marginBottom: 32 }}>{t("customerStaff:subtitle4")}</Typography>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div> <User /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("common:commonNameLbl")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && (clientStaff.firstName + ' ' + clientStaff.lastName)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <Email /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">Email</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && clientStaff.email}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <BriefCase /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("common:commonClientLbl")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && getStaffClientName(clientStaff.clientUsers)}
                                            </Typography>
                                        </div>
                                    </div>
                                    {/* <Divider className="divider-style" />
                                    <Typography className="app-form-title">Opciones</Typography> */}
                                    {/* {clientStaff && !clientStaff.disabled
                                        ?
                                        <Button className='button-spam-no-spam' variant="text"
                                            onClick={() => dispatch(clientStaffSpam(clientStaff.id))} startIcon={<Spam />}>
                                            Marcar como Spam
                                        </Button>
                                        :
                                        <Button className='button-spam-no-spam no-spam-icon'
                                            variant="text" startIcon={<CheckCircle className="no-spam-icon" />}
                                            onClick={() => dispatch(clientStaffSpam(clientStaff.id))}>
                                            No es Spam
                                        </Button>
                                    } */}
                                </TabPanel>
                                <TabPanel value="2" className='tab-select-page-assigments-clients-assigment'>
                                    <Typography className="clients-assigment-title">{t("customerStaff:subtitle6")}</Typography>
                                    <div style={{ width: '35%' }}>
                                        <TextFieldComponent Icon={<Search />} position='start' placeholder={t("common:commonSearchPlh")}
                                            valueInput={search} callback={onChange.bind(this)}
                                        />
                                    </div>
                                    <TableClientAssigment />
                                    <br />
                                    <PaginationCustom pages={pagesClient} activePage={activePageClient}
                                        onChange={page => dispatch(changeClientPage(page))} />
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </Modal>
            </div>
        </TableContainer>
    );

}

export default withRouter(TableUserAssigned);