export const stepOneValidation = (notification,t) => {
    var error = {};
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (notification.title === null || notification.title.length === 0 ||
        /^\s+$/.test(notification.title))
        error.title = t("errorTitle");
    if (notification.title.length > 80)
        error.title = t("errorMax80");

    if (notification.description === null || notification.description.length === 0 ||
        /^\s+$/.test(notification.description))
        error.description = t("errorDescription");
    // if (notification.description.length > 140)
    //     error.description = t("errorMax140");
    if (!notification.description.replaceAll(" ", "").match(onlyLetters))
    error.description = t("errorAlphabetLong");

    if (notification.companyId === null || notification.companyId.length === 0 ||
        /^\s+$/.test(notification.companyId))
        error.companyId = t("errorCompany");

    if (notification.officeId === null || notification.officeId.length === 0 ||
        /^\s+$/.test(notification.officeId))
        error.officeId = t("errorOffice");

    if (notification.clientId === null || notification.clientId.length === 0 ||
        /^\s+$/.test(notification.clientId))
        error.clientId = t("errorClient");

    return error;
};

export const stepSecondValidation = (notification,t) => {
    var error = {};
    if (notification.receivers.length === 0)
        error.receivers = t("errorDestination");
    return error;
};

