import React, { useState, useEffect, useCallback } from "react";
import { Container, Grid, Typography, InputLabel } from "@mui/material";
import Title from "../../../components/Title";
import SelectFieldComponent from "../../../components/SelectField";
import RecentDocuments from "./RecentDocuments";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessesFilter, getOfficesFilter } from "../../../redux/BusinessDuck";
import { getClients } from "../../../redux/ClientDuck";
import { getDocuments, getFolders, changeIsDeleted } from "../../../redux/DocumentDuck";
import { useTranslation } from "react-i18next";
const ReadDocuments = () => {

    const { t } = useTranslation(['document']);
    const businesses = useSelector(store => store.business.businesses);
    const clientsRedux = useSelector(store => store.client.clients);
    const activePageDoc = useSelector(store => store.doc.activePageDoc);
    const activePageFolder = useSelector(store => store.doc.activePageFolder);
    const officesRedux = useSelector(store => store.business.offices);
    const isCreatedFile = useSelector(store => store.doc.isCreatedFile);
    const deletedFolder = useSelector(store => store.doc.deletedFolder);
    const isDeletedFile = useSelector(store => store.doc.isDeletedFile);

    const [companyId, setCompanyId] = useState('all');
    const [companies, setCompanies] = useState([]);
    const [officeId, setOfficeId] = useState('all');
    const [offices, setOffices] = useState([]);
    const [clientId, setClientId] = useState('all');
    const [clients, setClients] = useState([]);
    const [searchDoc, setSearchDoc] = useState('');
    const [resetPageDoc, setResetPageDoc] = useState(false);
    const [searchFolder, setSearchFolder] = useState('');
    const [resetPageFolder, setResetPageFolder] = useState(false);

    const dispatch = useDispatch();
    const onChangeCompany = e => {
        setOfficeId("all");
        setClientId("all");
        setCompanyId(e.target.value);
        setResetPageDoc(true);
        setResetPageFolder(true);
    };

    const onChangeOffice = e => {
        setClientId("all");
        setOfficeId(e.target.value);
        setResetPageDoc(true);
        setResetPageFolder(true);
    };

    const onChangeClient = e => {
        setClientId(e.target.value);
        setResetPageDoc(true);
        setResetPageFolder(true);
    };

    const onChangeSearchDoc = e => {
        setSearchDoc(e.target.value);
        setResetPageDoc(true);
    };

    const onChangeSearchFolder = e => {
        setSearchFolder(e.target.value);
        setResetPageFolder(true);
    };

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [companyId, officesRedux]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== 'all' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);


    useEffect(() => {
        const filterBy = [{ search: 'false', filterBy: 'disabled' }]
        companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        dispatch(getClients(filterBy, 0, 10000, false, 'documents'));
    }, [dispatch, officeId, companyId]);

    useEffect(() => {
        if (companyId || officeId) {
            const clientList = [];
            clientsRedux.forEach(item => {
                clientList.push({ key: item.id, value: item.name });
            });
            setClients(clientList);
        }
    }, [clientsRedux, companyId, officeId]);

    const onSearchDoc = useCallback((searchDoc) => {
        const timer = setTimeout(() => {
            const page = resetPageDoc ? 0 : activePageDoc;
            const filterBy = [];
            searchDoc !== '' && filterBy.push({ search: searchDoc, filterBy: 'name' });
            clientId !== 'all' && filterBy.push({ search: String(clientId), filterBy: 'client' });
            dispatch(getDocuments(filterBy, "recent-files", page, 5, true, 'documents'))
            setResetPageDoc(false);
        }, 1000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, companyId, searchDoc, officeId, clientId]);

    useEffect(() => {
        if (clientId !== "all") {
            const page = resetPageDoc ? 0 : activePageDoc;
            const filterBy = [];
            searchDoc !== '' && filterBy.push({ search: searchDoc, filterBy: 'name' });
            clientId !== 'all' && filterBy.push({ search: String(clientId), filterBy: 'client' });
            dispatch(getDocuments(filterBy, "recent-files", page, 5, false, 'documents'))
            setResetPageDoc(false);
        }
        // eslint-disable-next-line
    }, [dispatch, activePageDoc]);

    useEffect(() => {
        if (clientId !== 'all' && (isCreatedFile || isDeletedFile)) {
            onSearchDoc(searchDoc);
            if (isDeletedFile)
                dispatch(changeIsDeleted());
        }
        // eslint-disable-next-line
    }, [onSearchDoc, isCreatedFile, isDeletedFile]);

    useEffect(() => {
        clientId !== 'all' &&
            onSearchDoc(searchDoc);
        // eslint-disable-next-line
    }, [onSearchDoc, clientId]);

    const onSearchFolder = useCallback((searchFolder) => {
        const timer = setTimeout(() => {
            const page = resetPageFolder ? 0 : activePageFolder;
            const filterBy = [];
            searchFolder !== '' && filterBy.push({ search: searchFolder, filterBy: 'name' });
            clientId !== 'all' && filterBy.push({ search: String(clientId), filterBy: 'client' });
            dispatch(getFolders(filterBy, page, 9));
            setResetPageFolder(false);
        }, 1000);
        return () => clearTimeout(timer);

        // eslint-disable-next-line
    }, [dispatch, searchFolder, companyId, officeId, clientId]);

    useEffect(() => {
        if (clientId !== "all") {
            const page = resetPageFolder ? 0 : activePageFolder;
            const filterBy = [];
            searchFolder !== '' && filterBy.push({ search: searchFolder, filterBy: 'name' });
            clientId !== 'all' && filterBy.push({ search: String(clientId), filterBy: 'client' });
            dispatch(getFolders(filterBy, page, 9));
            setResetPageFolder(false);
        }
        // eslint-disable-next-line
    }, [dispatch, activePageFolder]);

    useEffect(() => {
        if (clientId !== 'all' || deletedFolder) {
            onSearchFolder(searchFolder);
            onSearchDoc(searchDoc);
        }
        // eslint-disable-next-line
    }, [onSearchFolder, onSearchDoc, clientId, deletedFolder]);

    return (
        <Container>
            <Title title={t('title')} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography style={{ textTransform: "capitalize" }} className="app-form-title">{t('filters')}</Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between',
                    }}>
                        <div style={{ width: '31%' }}>
                            <InputLabel style={{ textTransform: "capitalize" }} className="label-app">{t('company')}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={companyId}
                                onChange={onChangeCompany}
                                options={[
                                    { key: 'all', value: t('all') },
                                    ...companies
                                ]}
                            />
                        </div>
                        <div style={{ width: '31%' }}>
                            <InputLabel style={{ textTransform: "capitalize" }} className="label-app">{t('office')}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                onChange={onChangeOffice}
                                options={[
                                    { key: 'all', value: t('all') },
                                    ...offices
                                ]}
                            />
                        </div>
                        <div style={{ width: '31%' }}>
                            <InputLabel style={{ textTransform: "capitalize" }} className="label-app">{t('client')}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={clientId}
                                onChange={onChangeClient}
                                options={[
                                    { key: 'all', value: '' },
                                    ...clients
                                ]}
                            />
                        </div>
                    </div>
                </div>
                {clientId !== 'all' &&
                    <RecentDocuments
                        searchDoc={searchDoc}
                        onChangeSearchDoc={onChangeSearchDoc}
                        onSearchDoc={onSearchDoc.bind(this, searchDoc)}

                        searchFolder={searchFolder}
                        onChangeSearchFolder={onChangeSearchFolder}
                        onSearchFolder={onSearchFolder.bind(this, searchFolder)}

                        clientId={clientId}
                    />
                }
            </Grid>
        </Container>
    );
}

export default ReadDocuments;