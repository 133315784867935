export const Validation = (subscription, t) => {

    var error = {};

    if (subscription.firstName === null || subscription.firstName.length === 0 ||
        /^\s+$/.test(subscription.firstName))
        error.firstName = t("subscription:errorFirstName");
    if (subscription.lastName === null || subscription.lastName.length === 0 ||
        /^\s+$/.test(subscription.LastName))
        error.lastName = t("subscription:errorLastName");
    if (subscription.email === null || subscription.email.length === 0 ||
        /^\s+$/.test(subscription.email))
        error.email = t("subscription:errorEmail");
    else if (!validateUserEmail(subscription.email))
        error.email = t("subscription:errorEmailFormat");
    if (subscription.orgName === null || subscription.orgName.length === 0 ||
        /^\s+$/.test(subscription.orgName))
        error.orgName = t("subscription:errorOrgName");
    if (subscription.countryCodeId === null || subscription.countryCodeId < 1)
        error.countryCode = t("subscription:errorCountryCode");

    if (subscription.termsAcceptance !== true || subscription.termsAcceptance === null)
        error.termsAcceptance = t("subscription:errorTermsAcceptance");

    if (subscription.subtotal === null || subscription.subtotal.length === 0 ||
        /^\s+$/.test(subscription.subtotal))
        error.subtotal = t("subscription:errorSubtotal");

    if (subscription.cardHolderName === null || subscription.cardHolderName.length === 0 ||
        /^\s+$/.test(subscription.cardHolderName))
        error.cardHolderName = t("subscription:errorCardHolderName");

    if (subscription.cardN === null || subscription.cardN.length === 0 ||
        /^\s+$/.test(subscription.cardN))
        error.cardN = t("subscription:errorCardN");

    if (subscription.CVV === null || subscription.CVV.length === 0 ||
        /^\s+$/.test(subscription.CVV))
        error.CVV = t("subscription:errorCVV");

    if (subscription.exp === null || subscription.exp.length === 0 ||
        /^\s+$/.test(subscription.exp))
        error.exp = t("subscription:errorExp");




    return error;
};

const validateUserEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});

