import {
    getUsersURL, getUserByIdURL, postUserURL,
    putUserDisableURL, putUserActiveURL,
    putUserURL,
    getRoleUserURL
} from '../constants/apiUrls';
import { postAxios, getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    users: [],
    user: null,
    pages: 1,
    activePage: 0,
    isCreating: true,
    isCreated: false,
    response: null,
    loading: false,
    error: null,
};

// types
const USERS_SUCCESS = 'USERS_SUCCESS';
const CREATE_UPDATE_USER_SUCCESS = 'CREATE_UPDATE_USER_SUCCESS';
const USER_GET_BY_ID = 'USER_GET_BY_ID';
const IS_CREATING_USER = 'IS_CREATING_USER';
const USER_STATUS_CHANGE = 'USER_STATUS_CHANGE';
const LOADING_USER = 'LOADING_USER';
const USER_CHANGE_PAGE = 'USER_CHANGE_PAGE';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
const USER_ERROR = 'USER_ERROR';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_USER:
            return { ...state, loading: true, error: null };
        case USERS_SUCCESS:
            return {
                ...state, users: action.payload, activePage: action.activePage,
                pages: action.pages, error: null, loading: false,
                isCreated: false, isCreating: true
            };
        case CREATE_UPDATE_USER_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                users: [], activePage: 0, pages: 1, loading: false, error: null
            };
        case CLEAR_USER_LIST:
            return { ...state, users: [] };
        case USER_GET_BY_ID:
            return {
                ...state, user: action.payload, error: null, loading: false,
                users: action.users, activePage: 0, pages: 1
            };
        case USER_STATUS_CHANGE:
            return { ...state, response: action.response, loading: false, error: null };
        case IS_CREATING_USER:
            return {
                ...state, isCreating: action.payload, user: null, isCreated: false,
                users: [], activePage: 0, pages: 1
            };
        case USER_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case USER_ERROR:
            return {
                ...state, error: action.payload,
                response: action.payload, loading: false
            };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                users: [],
                user: null,
                pages: 1,
                activePage: 0,
            };
        default:
            return state;
    }
};

export const getUsers = (filterBy = [], page = 0, pageSize = 10000, loading = false, service = "") => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_USER });
    try {
        const { user } = getState().auth;
        const res = await postAxios(getUsersURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "service": service,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: USERS_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages,
                activePage: (res.data.totalPages < page + 1) ? 0 : page
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && (error.response && (error.response.data.message === 'Unauthorized, User Disabled' && error.response.status === 401)))
            dispatch(logoutAction());
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error al cargar los usuarios.'
        });
    }
};

export const clearUserList = () => dispatch => {
    dispatch({ type: CLEAR_USER_LIST });
};

export const changeUserPage = (page) => (dispatch) => {
    dispatch({
        type: USER_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const getUserById = (id, list = false) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER });
    try {
        const { users } = getState().user;
        const res = await getAxios(getUserByIdURL, { id: id });
        if (res.status === 200) {
            const resRole = await getAxios(getRoleUserURL + id);
            if (resRole.status === 200) {
                const token = resRole.headers.refreshtoken;
                const expiration = resRole.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: USER_GET_BY_ID,
                    payload: {
                        ...res.data.response,
                        isAdmin: resRole.data.response.role.name === "Admin"
                    },
                    users: list ? users : []
                });
            }
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error al cargar el usuario.'
        });
    }
}

export const saveUser = (user, offices) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER });
    try {
        const userRedux = getState().auth.user;
        const bodyFormData = new FormData();
        var countOffice = 0;

        offices.forEach(item => {
            bodyFormData.append(`userOffices[${countOffice}][userId]`, "");
            bodyFormData.append(`userOffices[${countOffice}][officeId]`, item);
            countOffice++;
        });

        bodyFormData.append('email', user.email);
        bodyFormData.append('organizationId', userRedux.organizationId);
        bodyFormData.append('firstName', user.firstName);
        bodyFormData.append('lastName', user.lastName);
        bodyFormData.append('mobileAccess', user.mobileAccess);
        bodyFormData.append('countryCodeId', user.countryCode);
        bodyFormData.append('phone', user.phoneNumber);
        bodyFormData.append('position', user.position);
        bodyFormData.append('logo', user.image ? user.image : null);
        bodyFormData.append('profileDescription', user.profileDescription);
        const res = await postAxios(postUserURL, bodyFormData);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_USER_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error al crear el usuario.'
        });
    }
};

export const updateUser = (user, offices) => async (dispatch, getState) => {
    //console.log("changing user")
    dispatch({ type: LOADING_USER });
    try {
        const userRedux = getState().user.user;
        const bodyFormData = new FormData();
        var countOffice = 0;
        
        if (offices && offices.length === 0) bodyFormData.append('userOffices', '');
        offices && offices.forEach(item => {
            bodyFormData.append(`userOffices[${countOffice}][userId]`, userRedux.id);
            bodyFormData.append(`userOffices[${countOffice}][officeId]`, item);
            countOffice++;
        });

        bodyFormData.append('id', userRedux.id);
        bodyFormData.append('firstName', user.firstName);
        bodyFormData.append('lastName', user.lastName);
        bodyFormData.append('countryCodeId', user.countryCode);
        bodyFormData.append('phone', user.phoneNumber);
        bodyFormData.append('email', user.email);
        bodyFormData.append('position', user.position);
        bodyFormData.append('logo', user.image ? user.image : null);
        bodyFormData.append('profileDescription', user.profileDescription);
        bodyFormData.append('organizationId', userRedux.organizationId);

        const res = await putAxios(putUserURL, bodyFormData);
        //console.log("res data",res);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_USER_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        //console.log(error);
        
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'common:UpdateUserError'
        });
    }
};

export const changeStatusUser = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER });
    try {
        const { user } = getState().user;
        const res = (user.status === 1 || user.status === 0)
            ? await putAxios(putUserDisableURL + id)
            : await putAxios(putUserActiveURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: USER_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getUserById(id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error al activar/archivar el usuario.'
        });
    }
}

export const changeIsCreating = (state) => (dispatch) => {
    dispatch({
        type: IS_CREATING_USER,
        payload: state
    });
};