import React, { useState, useEffect } from 'react';
import {
    Typography, IconButton,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Direccionpoint } from '../../../assets/Icons/direccionpoint.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import IconSvg from '../../../components/Icon';
// import SelectFieldComponent from "../../../components/SelectField";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useSelector } from 'react-redux';
import './DragDropItem.css';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const googleMapsAPI = process.env.REACT_APP_GOOGLE_API_KEY;
const mapStyles = {
    height: '257px',
    position: 'relative'
};

const MapItem = (props) => {

    const { t } = useTranslation(['mobileContent', 'common']);

    const { index, pageIndex, value = null, onMapChange, deleteContent, error = [] } = props;
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const [language, /*setLanguage*/] = useState(defaultLanguageId);
    // const [languageList, setLanguageList] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({
        lat: 9.93593727277584,
        lng: -84.10095092339176
    });
    const zoom = 15;

    useEffect(() => {
        const temp = value[language] ? value[language].map : '';
        const position = temp.split(',');
        if (position[0] && position[1])
            setMarkerPosition({ lat: position[0], lng: position[1] });
    }, [value, language]);

    // useEffect(() => {
    //     const list = [];
    //     lenguages
    //         .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
    //         .forEach(item => {
    //             list.push({
    //                 key: item.key,
    //                 value: <span style={{ color: '#5AB6DF' }}>{item.code.toUpperCase()}</span>
    //             });
    //         });
    //     setLanguageList(list);
    // }, [lenguages, secondaryLanguageId, defaultLanguageId]);

    const onMarkerDragEnd = event => {
        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();
        setMarkerPosition({ lat: latitude, lng: longitude });
        lenguages.forEach(({ key }) => {
            onMapChange(pageIndex, index - 1, {
                id: (value && value[key]) ? value[key].id : null,
                map: `${latitude}, ${longitude}`
            }, key);
        });
    };

    const mapTrack = <Map google={props.google} zoom={zoom} style={mapStyles}
        initialCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        center={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        onClick={(t, map, coord) => onMarkerDragEnd(coord)}
    >
        <Marker name='Ubicación' title='Marcador'
            position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
            draggable={false}
        />
    </Map>

    const searchBox = useRef(null);

    useEffect(() => {
        const input = document.getElementById("search-google-maps-" + index);
        searchBox.current = new props.google.maps.places.SearchBox(input);
    }, [index, props.google.maps.places.SearchBox]);

    if (searchBox.current) {
        searchBox.current.addListener("places_changed", () => {
            const places = searchBox.current.getPlaces();

            if (places && places.length === 0) return;

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) return;
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                setMarkerPosition({ lat: latitude, lng: longitude });
                lenguages.forEach(({ key }) => {
                    onMapChange(pageIndex, index - 1, {
                        id: (value && value[key]) ? value[key].id : null,
                        map: `${latitude}, ${longitude}`
                    }, key);
                });
                // onMapChange(pageIndex, index - 1, {
                //     id: value[language] ? value[language].id : null,
                //     map: `${latitude}, ${longitude}`
                // }, language);
            });
        });
    }

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '50%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={Direccionpoint} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("mapLbl")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        width: '48%', justifyContent: 'flex-end'
                    }}>
                        {/* <SelectFieldComponent
                            name="language"
                            valueInput={language}
                            options={[
                                ...languageList
                            ]}
                            onChange={e => setLanguage(e.target.value)}
                        /> */}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <Edit style={{ margin: '0 20px' }} />
                            <Files style={{ marginRight: 20 }} />
                            <Drag />
                        </div>
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails className='panel-details'>
                <Typography className="app-form-title">{t("subtitle24")}</Typography>
                <div className='container-google-map'>
                    <input
                        id={"search-google-maps-" + index}
                        className="controls search-google-maps"
                        type="text"
                        placeholder={t("mapSearchPlh")}
                    />
                    {mapTrack}
                </div>
                <div style={{ display: 'flex', marginBottom: 18, justifyContent: 'flex-end' }}>
                    <div style={{ marginTop: 25 }}>
                        <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                            <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                        </IconButton>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion >
    );
}

const LoadingContainer = () => (
    <div>Cargando...</div>
);

const GoogleWrapper = GoogleApiWrapper({
    apiKey: (googleMapsAPI),
    LoadingContainer: LoadingContainer,
    language: localStorage.getItem('lng-fos') ? (localStorage.getItem('lng-fos') === "1" ? "en" : "es") : "en",
    region: localStorage.getItem('region') ? localStorage.getItem('region') : "CR"
})(MapItem);

export default GoogleWrapper;