import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography, InputLabel, Avatar, IconButton,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Blueuser } from '../../../assets/Icons/blueuser.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import SelectFieldComponent from "../../../components/SelectField";
import Checkbox from '../../../components/Checkbox';
import CircleIcon from '@mui/icons-material/Circle';
import IconSvg from '../../../components/Icon';
import './DragDropItem.css';
import { getUserById, getUsers } from '../../../redux/UserDuck'
import { getOfficesFilter, getBusinessesFilter } from '../../../redux/BusinessDuck'
import { getFileURL } from '../../../constants/apiUrls';
import { useTranslation } from 'react-i18next';

const UserData = ({ index, pageIndex, value = null, onUserCardChange, deleteContent,
    error = null }) => {

    const { t } = useTranslation(['mobileContent', 'common']);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const userAuth = useSelector(store => store.auth.user);
    const users = useSelector(store => store.user.users);
    const offices = useSelector(store => store.business.offices);
    const businesses = useSelector(store => store.business.businesses);
    const user = useSelector(store => store.user.user);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const [language, /*setLanguage*/] = useState(defaultLanguageId);

    const [userList, setUserList] = useState([]);
    const [businessId, setBusinessId] = useState('all');
    const [officeId, setOfficeId] = useState('all');
    const [businessList, setBusinessList] = useState([]);
    const [officeList, setOfficeList] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const [userData, setUserData] = useState({
        id: null,
        userId: '',
        fullName: false,
        email: false,
        phone: false,
        position: false,
        downloadable: ''
    });
    const dispatch = useDispatch();

    const status = [
        { status: t("common:commonStatusProcessing"), color: '#6C757D' },
        { status: t("common:commonStatusActive"), color: '#28A745' },
        { status: t("common:commonStatusArchived"), color: '#6C757D' },
    ];

    const onUserChange = (e) => {
        const { value } = e.target;
        setUserData({ ...userData, userId: value });
    };

    useEffect(() => {
        dispatch(getUserById(userData.userId, true));
    }, [dispatch, userData.userId]);

    useEffect(() => {
        const list = [];
        if (userAuth) {
            users.forEach(item => {
                list.push({
                    key: item.id,
                    value: item.firstName + ' ' + item.lastName,
                });
            });
            setUserList(list);
        }
    }, [users, userAuth]);

    useEffect(() => {
        const officesTemp = [];
        offices.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOfficeList(officesTemp);
        setOfficeId('all');
    }, [businessId, offices]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(businessId !== 'all' ? [{
            "search": String(businessId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, businessId]);

    useEffect(() => {
        const list = [];
        businesses.forEach(item => {
            const officeList = [];
            item.offices.forEach(office => {
                officeList.push({ key: office.id, value: office.name });
            });
            list.push({
                key: item.id, value: item.name,
                offices: officeList
            });
        });
        setBusinessList(list);
    }, [businesses]);

    const changeBusinessId = e => {
        setBusinessId(e.target.value);
        setOfficeId('all');
        setUserData({ ...userData, userId: '' });
    };

    const onChange = e => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const onCheckbox = e => {
        const { checked, name } = e.target;
        setUserData({ ...userData, [name]: checked });
    };

    useEffect(() => {
        if (value[defaultLanguageId]) {
            setUserData(value[defaultLanguageId]);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        lenguages
            .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
            .forEach(({ key }) => {
                onUserCardChange(pageIndex, index - 1, userData, key);
            });
        // eslint-disable-next-line
    }, [userData]);

    useEffect(() => {
        const filterBy = [];
        businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        dispatch(getUsers(filterBy, 0, 10000, false, "userCard"));
    }, [dispatch, businessId, officeId]);

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '50%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={Blueuser} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("userCardLbl")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                    }}>
                        <Edit style={{ marginRight: 20 }} />
                        <Files style={{ marginRight: 20 }} />
                        <Drag />
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails className='panel-details'>
                <Typography className="app-form-title">
                    {t("subtitle10")}
                </Typography>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 24
                }}>
                    <div style={{ width: '48%' }}>
                        <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                        <SelectFieldComponent name="businessName"
                            valueInput={businessId}
                            options={[
                                { key: 'all', value: t("common:commonAllLbl") },
                                ...businessList
                            ]}
                            onChange={changeBusinessId}
                        />
                    </div>
                    <div style={{ width: '48%' }}>
                        <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                        <SelectFieldComponent name="officesName"
                            valueInput={officeId}
                            options={[{ key: 'all', value: t("common:commonAllLbl") }, ...officeList]}
                            onChange={e => setOfficeId(e.target.value)}
                        />
                    </div>
                </div>
                <InputLabel className="label-app"> {t("common:commonUserLbl")}</InputLabel>
                <SelectFieldComponent name="userName"
                    valueInput={userList.length === 0 ? '' : userData.userId}
                    onChange={onUserChange}
                    options={userList.length === 0
                        ? [{ key: '', value: t("noUsersFound") }]
                        : userList}
                />
                {(userData.userId && user && userList.length > 0) &&
                    <>
                        <div style={{
                            display: 'flex', marginTop: 18, marginBottom: 32
                        }}>
                            <Avatar
                                className='avatar-user-form'
                                alt={userList.find(item => item.key === userData.userId).value}
                                src={getFileURL + user.fileId}
                                sx={{ width: 112, height: 112 }}
                            />
                            <div style={{ marginLeft: 37 }}>
                                <Typography className="user-name-form">
                                    {userList.find(item => item.key === userData.userId).value}
                                </Typography>
                                <Typography className="job-user-form" style={{ margin: '4px 0px' }}>
                                    {user.organizationUser.position}
                                </Typography>
                                <div style={{
                                    display: 'flex', alignItems: 'center',
                                    color: status[user.status].color, margin: '9px 0px'
                                }}>
                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                    <Typography>
                                        {status[user.status].status}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Typography className="app-form-subtitle">
                            {t("subtitle11")}
                        </Typography>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    name='fullName'
                                    onChange={onCheckbox}
                                    value={userData.fullName}
                                    checkboxCheckedValue={true}
                                />
                                <Typography className="app-form-subtitle">
                                    {t("fullnameLbl")}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    name='email'
                                    onChange={onCheckbox}
                                    value={userData.email}
                                    checkboxCheckedValue={true}
                                />
                                <Typography className="app-form-subtitle">
                                    {t("emailLbl")}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    name='phone'
                                    onChange={onCheckbox}
                                    value={userData.phone}
                                    checkboxCheckedValue={true}
                                />
                                <Typography className="app-form-subtitle">
                                    {t("phoneLbl")}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    name='position'
                                    onChange={onCheckbox}
                                    value={userData.position}
                                    checkboxCheckedValue={true}
                                />
                                <Typography className="app-form-subtitle">
                                    {t("positionLbl")}
                                </Typography>
                            </div>
                        </div>
                        <Typography className="app-form-subtitle">
                            {t("subtitle12")}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <label className="config-app-language-form-app">{t("common:commonYes")}
                                <input type="radio" name="downloadable"
                                    checked={userData.downloadable === 'true'}
                                    value="true" onChange={onChange} />
                                <span className="config-app-language-checkmark"></span>
                            </label>
                            <label className="config-app-language-form-app">{t("common:commonNo")}
                                <input type="radio" name="downloadable" value="false"
                                    checked={userData.downloadable === 'false'}
                                    onChange={onChange} />
                                <span className="config-app-language-checkmark"></span>
                            </label>
                        </div>
                    </>
                }
                <div style={{ display: 'flex', marginBottom: 18, justifyContent: 'flex-end' }}>
                    <div style={{ marginTop: 25 }}>
                        <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                            <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                        </IconButton>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default UserData;