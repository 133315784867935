import React, { useState, useEffect, useRef } from 'react';
import { withRouter} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  Button, Accordion, AccordionDetails, AccordionSummary, Alert } from '@mui/material';
import TextFieldComponent from '../../components/TextField';
import SelectFieldComponent from "../../components/SelectField";
import Loading from '../../components/Loading';

//import Logo from '../../assets/Images/Login.png';
import SwitchToggleComponent from "../../components/SwitchToggle";
import Checkbox from "../../components/Checkbox";
import { signUpAction} from '../../redux/SubscriptionDuck';
import { useTranslation } from 'react-i18next';
import {  getCountriesEN } from "../../redux/CountryDuck";
import { Validation } from "./SubscriptionValidation";
import './Registration.css';
import Logo from '../../assets/Logos/navbarLogo.png';



const Registration = props => {


    const { t } = useTranslation(['login', 'validations', 'auth', 'subscription']);
    const [checkoutVisible, setCheckoutVisible] = useState(false);
    const countries = useSelector(store => store.country.countries);
    const checkoutError = useSelector(store => store.auth.alertMessage);
    const redirectToThankYou = useSelector(store => store.auth.redirectToThankYou);
    
    const inputRef = useRef(null);
    const [monthlyBilling, setMonthlyBilling] = useState(true);
    const [termsAcceptance, setTermsAcceptance] = useState(false);
    const [subscriptionError, setsubscriptionError] = useState({});
    
    //const [checkoutError, setCheckoutError] = useState("");

    const [selectedPlan, setSelectedPlan] = useState({
        "name": "",
        "cantidad_de_usuarios": '',
        "costo_por_usuario_plan_mensual": '',
        "costo_por_usuario_plan_anual": '',
        "total_plan_mensual": '',
        "total_plan_anual": '',
        "total_final": ''
    });
    /*
    const [subscription, setSubscription] = useState({
        firstName: 'Juan',
        lastName: 'Alpizar',
        email: 'juanfe_a27@outlook.com',
        orgName: 'RPLJ',
        countryCodeId: 12,
        countryName: 'CRC',
        phoneNumber: '87046694',
        cardHolderName: 'Juan Felipe A',
        cardN: '4242424242424242',
        exp: "2023-12",
        CVV: '424',
        termsAcceptance: true,
        subtotal: '650',
        discountPeriod: '2',
       
        planName: 'startup',
        final: '0'
    });*/
    const [subscription, setSubscription] = useState({
        firstName: '',
        lastName: '',
        email: '',
        orgName: '',
        countryCodeId: 0,
        countryName: '',
        phoneNumber: '',
        cardHolderName: '',
        cardN: '',
        exp: '',
        CVV: '',
        termsAcceptance: false,
        subtotal: '',
        discountPeriod: '',
       
        planName: '',
        final: ''
    });
    
    const [discountTerm, setDiscountTerm] = useState(0);
    const [discountRatio, setDiscountRatio] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [form, setForm] = useState({
        type: 'text', required: false
    });

    
    const dispatch = useDispatch();
    useEffect(() => {

        // inputRef.focus();
    }, []);
    useEffect(() => {
        console.log("check error",checkoutError)
        // inputRef.focus();
    }, [checkoutError]);
    useEffect(() => {
        //  console.log("selectedPlan ",selectedPlan);
        let subtotal = "$" + (selectedPlan && monthlyBilling ?
            selectedPlan.total_plan_mensual : selectedPlan.total_plan_anual);

       setForm({ ...form, subtotal: subtotal });
       setSubscription({ ...subscription, planName: selectedPlan.name });
       setSubscription({ ...subscription, final:parseInt(selectedPlan.total_final).toFixed(2) });
    }, [selectedPlan,monthlyBilling,subscription]);
    useEffect(() => {
        setSubscription({ ...subscription, termsAcceptance: termsAcceptance });
        
    }, [termsAcceptance]);
    useEffect(() => {
        UpdateTotal();
        setSubscription({ ...subscription, discountPeriod: discountTerm });
    }, [discount,discountTerm]);
    useEffect(() => {
        let sub = subscription;

        Object.assign(sub, form);
        setSubscription(sub);
        //console.log(form, sub);
    }, [form]);
    useEffect(() => {
        dispatch(getCountriesEN())
    }, [dispatch]);
    useEffect(() => {
        // console.log("monthly billing?",monthlyBilling);
        if (selectedPlan.total_final) {

            UpdateTotal();
        }
        //       dispatch(clearError());
    }, [monthlyBilling]);


    const onChangeForm = e => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });

    };
    const onChangeFormCountry = e => {
        setForm({ ...form, countryCodeId: e.target.value });

    };
    const onSubscribe = e => {
        //   const error = Validation(e,t);
        console.log("form", subscription);
        
        //console.log("ccid", subscription.countryCodeId,subscription.countryCodeId === null);
        //console.log("terms", subscription.termsAcceptance);
        //console.log("terms valid", (subscription.termsAcceptance!= true ||  subscription.termsAcceptance === null  ));
        
        const error = Validation(subscription, t);
        setsubscriptionError(error);
        console.log("errors:", error);
        if (Object.entries(error).length === 0) {
           // dispatch(signUpAction(form))
            dispatch(signUpAction(subscription))
        } else {
            document.querySelector('#subscription-accordion').scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const handleToggle = () => {
        monthlyBilling ? setMonthlyBilling(false) : setMonthlyBilling(true);
        //console.log("has final?",selectedPlan.total_final)
    }
    const handleToggleTerms = (e) => {
        //const { checked } = e.target;
        //setTermsAcceptance(checked)
        termsAcceptance ? setTermsAcceptance(false) : setTermsAcceptance(true);
        //setForm({ ...form, termsAcceptance: !termsAcceptance });
        //console.log(e.target,"termsAcc?",termsAcceptance,"form?",subscription.termsAcceptance);

    }

    function UpdateTotal(currentSelection = "") {
        if (!currentSelection) {
            currentSelection = selectedPlan;
        }
        let final = monthlyBilling ? currentSelection.total_plan_mensual : currentSelection.total_plan_anual;
        console.log("final",final);
        if (discountRatio) {
            
            final = final - ApplyDiscount()
        }
        //console.log("discount",discountRatio,"final",final,ApplyDiscount());
        const updatedSelectedPlan = { ...currentSelection, total_final: final }
        setSelectedPlan(updatedSelectedPlan);
    }
    const handleCouponInput = (e, coupon) => {

        const found = e.target.value && Object.entries(mockCoupons).find(([key, array]) => array.includes(e.target.value));
        console.log(found ? `Code found in array with key "${found[0]}" at index ${found[1].indexOf(e.target.value)}` : 'Code not found');
       
        if (found) {
            let amount = found[0] / 100;
            
            setDiscountRatio(amount);
            let termIndex = found[1].indexOf(e.target.value);
            let term = CouponTypes[termIndex];
            setDiscountTerm(term);
            let subtotal = (monthlyBilling ? selectedPlan.total_plan_mensual : selectedPlan.total_plan_anual)
            let planTotal = 0;
            planTotal = roundToNearestFive(subtotal * (1 - amount))
            setDiscount(subtotal - planTotal);
            
        } 


        //console.log(coupon,e.target,);
    }
    function ApplyDiscount() {
        let amount = discountRatio;
        let subtotal = (monthlyBilling ? selectedPlan.total_plan_mensual : selectedPlan.total_plan_anual)
        let planTotal = 0;
        planTotal = roundToNearestFive(subtotal * (1 - amount))
        setDiscount(subtotal - planTotal);
        //console.log(amount, "1-amount", (1 - amount), "total", planTotal, "total discounted", planTotal * (1 - amount))
        return (subtotal - planTotal);

    }
    const expandCheckout = (value) => {
        // console.log("clicked on ",value);
       

        UpdateTotal(mockplans.at(value));

        setCheckoutVisible(true);
        document.querySelector('#subscription-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    }



    function roundToNearestFive(num) {
        return Math.round(num * 20) / 20;
    }
    const CouponTypes = [1, 2, 3, 6, 12, "Permanent"];

    const mockCoupons = {

        "10": ["11GY1001", "11GR1002", "11GY1003", "11GR1006", "11GY1012", "11WW1000"],
        "20": ["22TP2001", "22YP2002", "22TP2003", "22YP2006", "22TP2012", "22HH2000"],
        "30": ["33YG3001", "33ML3002", "33YG3003", "33ML3006", "33YG3012", "33NN3000"],
        "40": ["44MP4001", "44AZ4002", "44MP4003", "44AZ4006", "44MP4012", "44BB4000"],
        "50": ["55QE5001", "55OL5002", "55QE5003", "55OL5006", "55QE5012", "55FF5000"],
        "75": ["77TB7501", "77AP7502", "77TB7503", "77AP7506", "77TB7512", "77TT7500"],
        "100": ["99JU10001", "99FF10002", "99JU10003", "99FF10006", "99JU10012", "99CC10000"],
        "-50": ["", "", "", "", "", "SDF50"],
        "-100": ["", "", "", "", "", "FRT100"],
        "-200": ["", "", "", "", "", "HKL200"],
        "-300": ["", "", "", "", "", "KPG300"],
        "-400": ["", "", "", "", "", "FRP400"],
        "-500": ["", "", "", "", "", "ZVM500"],
        "-600": ["", "", "", "", "", "BTJ600"],
        "-700": ["", "", "", "", "", "LWF700"]
    };
    const colors = [
        { "band-color": "#16acea", "title-color": "#fff" },
        { "band-color": "#5ab6df", "title-color": "#fff" },
        { "band-color": "#bee3e9", "title-color": "#959393" },
        { "band-color": "#aba3d0", "title-color": "#fff" },
        { "band-color": "#8976b6", "title-color": "#fff" },
        { "band-color": "#fff", "title-color": "#fff" }

    ]
    const mockplans = [
        {
            "name": "Startup",
            "cantidad_de_usuarios": 5,
            "costo_por_usuario_plan_mensual": 29,
            "costo_por_usuario_plan_anual": 29,
            "total_plan_mensual": 145,
            "total_plan_anual": 1450
        },
        {
            "name": "Basic",
            "cantidad_de_usuarios": 10,
            "costo_por_usuario_plan_mensual": 27,
            "costo_por_usuario_plan_anual": 27,
            "total_plan_mensual": 270,
            "total_plan_anual": 2700
        },
        {
            "name": "Standard",
            "cantidad_de_usuarios": 25,
            "costo_por_usuario_plan_mensual": 25,
            "costo_por_usuario_plan_anual": 25,
            "total_plan_mensual": 625,
            "total_plan_anual": 6250
        },
        {
            "name": "Corporate",
            "cantidad_de_usuarios": 50,
            "costo_por_usuario_plan_mensual": 23,
            "costo_por_usuario_plan_anual": 23,
            "total_plan_mensual": 1150,
            "total_plan_anual": 11150
        },
        {
            "name": "Enterprise",
            "cantidad_de_usuarios": +50,
            "costo_por_usuario_plan_mensual": 21,
            "costo_por_usuario_plan_anual": 21,
            "total_plan_mensual": "-",
            "total_plan_anual": "-"
        },
    ];


    if (!redirectToThankYou){
        return (
            <div className="registration-body" style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                <div className='registration-section'>
                    <div className='reg-div header'>
                        <div className="header-logo"><img src={Logo} alt="Logo" /></div>
                        <div className="header-nav">
                            <a ahref="https://fos.services/"> Back Home</a>
                        </div>
                    </div>
                    <div className='reg-div title'>
                        <h5>You're going to love FOS</h5>
                        <h3>Choose your subscription plan</h3>
    
                    </div>
                    <div className='reg-div-selector'><span className={monthlyBilling ? "active" : "disabled"}>Monthly</span><SwitchToggleComponent defaultChecked={false} callback={handleToggle}></SwitchToggleComponent><span className={!monthlyBilling ? "active" : "disabled"}>Yearly</span></div>
                    <div className='reg-div-yearly-offer' >
                        <h3 style={{ opacity: monthlyBilling ? 0 : 1 }} >2 Months Free </h3>
    
                    </div>
    
                    <div className='reg-div cards' >
    
                        {(mockplans) && Array.isArray(mockplans) &&
                            mockplans.map((row, index) => (
                                <div className='reg-card-container' key={index}>
                                    <div className="reg-card">
                                        <div className='plan-title' style={{ backgroundColor: colors[index]["band-color"] }}>
                                            <div className="plan-label-app" style={{ fontSize: 'inherit', color: colors[index]["title-color"] }}>{row.name}</div>
                                        </div>
                                        <div className="plan-prices">
                                            <div className='perUserCost'>${monthlyBilling ? row.costo_por_usuario_plan_mensual : row.costo_por_usuario_plan_anual}<span>/user</span></div>
    
                                            <div className='maxUsers'>up to {row.cantidad_de_usuarios} users </div>
    
                                            <div> Unlimited mobile users </div>
    
                                            {
                                                index !== 4 ? <div className='totalMonthly'>Total {monthlyBilling ? "Monthly" : "Annual"} : ${monthlyBilling ? row.total_plan_mensual.toLocaleString("en-us") : row.total_plan_anual.toLocaleString("en-us")}</div>
                                                    :
                                                    <div className='totalMonthly'> Contact Us </div>
                                            }
    
    
                                        </div>
    
    
                                    </div>
    
                                    {
                                        index !== 4 ?
                                            <Button variant="contained" value={index} className="button-app-save" onClick={() => expandCheckout(index)}>
                                                <span>Subscribe  </span>
                                            </Button>
                                            :
                                            <Button variant="contained" value={index} className="button-app-save" >
                                                <a style={{ textDecoration: "none", color: 'white' }} href="https://fos.services/#contact">Contact Us</a>
    
                                            </Button>
                                    }
                                </div>
    
    
                            ))
    
                        }
                    </div>
    
                </div>
                <Accordion className="accordion-sumary-row-title" id="subscription-accordion" expanded={checkoutVisible} ref={inputRef} >
                    <AccordionSummary style={{ display: 'none' }}>
    
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='registration-section subscription-data' >
                            <div className='subscription column'>
    
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > First Name
                                    </label>
    
                                    <TextFieldComponent name="firstName"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.firstName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.firstName}
                                    />
                                    
    
                                </div>
    
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Last Name
                                    </label>
    
                                    <TextFieldComponent name="lastName"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.lastName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.lastName}
                                    />
    
    
                                </div>
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Email
                                    </label>
    
                                    <TextFieldComponent name="email"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.email}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.email}
                                    />
    
    
                                </div>
    
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Organization Name
                                    </label>
    
                                    <TextFieldComponent name="orgName"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.orgName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.orgName}
                                    />
    
    
                                </div>
    
    
                                <div className="subscriptionformField">
                                   <label  className=" subscription-form label" >Country
                                   </label>
                                   <SelectFieldComponent
    
                                       className="subscriptionCountries"
                                       name="countryCodeId"
                                       //onChange={onCountryChange.bind(this)}
                                       onChange={onChangeFormCountry}
                                       valueInput={form.countryCodeId}
                                       options={[...countries]}
                                       errorInput={subscriptionError.countryCode}
                                   />
                                   
                                </div>
                                <div className="subscriptionformField" style={{marginTop:"40px"}}>
                                <Checkbox name="termsAcceptance" 
                                        value={!termsAcceptance}
                                        checkboxCheckedValue={false}
                                        errorInput={subscriptionError.termsAcceptance}
                                        onChange={handleToggleTerms}>
                                        </Checkbox>  
                                        I understand and accept the <a target="_blank" rel="noreferrer" href="https://fos.services/fos-terms-of-use-and-service-agreement/">Terms & Conditions</a> <br />
                                    and <a target="_blank" rel="noreferrer" href="https://fos.services/privacy-policy/"> Privacy Policy</a>
                                    
                                </div>
                                <p className='app-error-text'>{subscriptionError.termsAcceptance}</p>
                            </div>
                            <div className='subscription column'>
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Subtotal
                                    </label>
    
                                    <TextFieldComponent name="subtotal"
                                        className="subsText noBorder"
                                        type="montextth"
                                        valueInput={form.subtotal}
                                        errorInput={subscriptionError.subtotal}
                                        readOnly
                                    />
    
    
                                </div>
    
                                <div className="subscriptionformField">
                                
                                <label className=" subscription-form label" >Redeem Code
    
                                </label>
                                    <input type="text" className="inputfull"
                                     name="Code" onChange={onChangeForm} onBlur={handleCouponInput} />
                                </div>
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Name on Card
                                    </label>
    
                                    <TextFieldComponent name="cardHolderName"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.cardHolderName}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.cardHolderName}
    
                                    />
    
    
                                </div>
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Card Number
                                    </label>
    
                                    <TextFieldComponent name="cardN"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.cardN}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.cardN}
    
                                    />
    
    
                                </div>
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > CVV
                                    </label>
    
                                    <TextFieldComponent name="CVV"
                                        className="subsText"
                                        type="text"
                                        valueInput={form.CVV}
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.CVV}
    
                                    />
    
    
                                </div>
    
                                <div className="subscriptionformField">
    
                                    <label className=" subscription-form label" > Expiration Date
                                    </label>
    
                                    <TextFieldComponent name="exp"
                                        className="subsText"
                                        type="month"
                                        row={0}
                                        valueInput={form.exp}
                                        min="2023-01"
                                        callback={onChangeForm}
                                        errorInput={subscriptionError.exp}
    
                                    />
    
    
                                </div>
    
                                {discount > 0 &&
                                    <label className=" subscription-form label full" >Discount Applied <input type="text" className='noBorder' value={"$" + discount} readOnly /></label>
    
                                }
                                {discountTerm !== 0 &&
                                    <>
    
                                        <label className=" subscription-form label full" >Discount Period    <input type="text" className='noBorder' value={discountTerm + " months"} readOnly /></label>
    
                                        <span>
                                            Once the term of your redeem code ends, the full amount of your subscription will be charged.
                                        </span>
                                    </>
                                }
                                 {
                                    checkoutError &&
                                    <Alert severity="error" className='change-pass-alert'> {checkoutError}</Alert>
                                  }
                                  <div className="subscriptionformField total">
                                <label className=" subscription-form label full total"   > {monthlyBilling ? "Total Monthly Due" : "Total Annual Due"}
                                </label>
                                    <span name="finalChargeDue">{"$" + parseInt(selectedPlan.total_final).toFixed(2)}</span>
                                    
                                  </div>
                                  <Loading />
                                <Button variant="contained" className="button-app-submit"
                                    onClick={() => onSubscribe()}
                                >
                                    Submit and Subscribe
                                </Button>
    
                            </div>
    
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className='registration-section footer' style={{ height: "300px" }}>
                    <span className="footer-info">
                        Copyrights Reserved FOS Systems – 2023
    
                    </span>
                </div>
            </div>
    
    
        );
    }
    else{
       window.location.href = 'http://fos.services/thankyou';
      return null;
    }
}

export default withRouter(Registration);