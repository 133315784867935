import React from "react";
import './SwitchToggle.css';

const SwitchToggleComponent = ({ callback, name, checked }) => {

    return (
        <label className="SwitchToggle">
            <input type="checkbox"
                onChange={callback}
                checked={checked}
                name={name} />
            <span className="slider-toggle round"></span>
        </label>
    );
}
export default SwitchToggleComponent;