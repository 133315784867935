import React, { useState, useEffect } from "react";
import { InputLabel, Typography } from "@mui/material";
import SelectFieldComponent from "../../../components/SelectField";
import { useSelector, useDispatch } from "react-redux";
import TextFieldComponent from "../../../components/TextField";
import { getBusinessesFilter, getOfficesFilter } from "../../../redux/BusinessDuck";
import { getClients } from "../../../redux/ClientDuck";
import { useTranslation } from 'react-i18next';
const FirtsStep = ({ setValues, notification, error }) => {
    const { t } = useTranslation(['notification', 'common', 'label']);
    const businesses = useSelector(store => store.business.businesses);
    const clientsRedux = useSelector(store => store.client.clients);
    const officesRedux = useSelector(store => store.business.offices);
    const [companyId, setCompanyId] = useState('');
    const [companies, setCompanies] = useState([]);
    const [officeId, setOfficeId] = useState('');
    const [offices, setOffices] = useState([]);
    const [clientId, setClientId] = useState('');
    const [clients, setClients] = useState([]);
    const dispatch = useDispatch();

    const onChangeCompany = e => {
        setCompanyId(e.target.value);
        setOffices([]);
        setClients([]);
        setValues({
            ...notification, companyId: e.target.value,
            officeId: '', clientId: ''
        });
    };

    const onChangeOffice = e => {
        setOfficeId(e.target.value);
        setClients([]);
        setValues({
            ...notification, officeId: e.target.value,
            clientId: ''
        });
    };

    const onChangeClient = e => {
        const { value } = e.target;
        setClientId(value);
        setValues({ ...notification, clientId: value });
    };

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        if (notification.officeId && officesTemp.find(o => String(o.key) === String(notification.officeId))) {
            setOfficeId(notification.officeId);
        } else {
            setOfficeId('');
        }
        // eslint-disable-next-line
    }, [companyId, officesRedux]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== '' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);

    useEffect(() => {
        if (officeId) {
            dispatch(getClients([
                { search: 'false', filterBy: 'disabled' },
                { search: String(officeId), filterBy: 'office' },
            ], 0, 10000, false, 'notification'));
        }
    }, [dispatch, officeId]);

    useEffect(() => {
        const clientList = [];
        clientsRedux.forEach(item => {
            clientList.push({ key: item.id, value: item.name });
        });
        setClients(clientList);
        // eslint-disable-next-line
    }, [clientsRedux]);

    const onChange = e => {
        const { name, value } = e.target;
        setValues({ ...notification, [name]: value });
    };

    useEffect(() => {
        setCompanyId(notification.companyId);
    }, [companyId, notification.companyId]);

    useEffect(() => {
        setOfficeId(notification.officeId);
    }, [officeId, notification.officeId]);

    useEffect(() => {
        setClientId(notification.clientId);
    }, [clientId, notification.clientId]);

    return (
        <div>
            <div className="container-app-div">
                <Typography className="app-form-title" style={{ marginTop: 22 }}>{t("common:commonFilterLbl")}s</Typography>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    width: '100%', justifyContent: 'space-between',
                    marginBottom: 20
                }}>
                    <div style={{ width: '31%' }}>
                        <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                        <SelectFieldComponent name="businessName"
                            valueInput={companyId}
                            onChange={onChangeCompany}
                            options={companies}
                            errorInput={error.companyId}
                        />
                    </div>
                    <div style={{ width: '31%' }}>
                        <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                        <SelectFieldComponent name="officesName"
                            valueInput={officeId}
                            onChange={onChangeOffice}
                            options={offices}
                            errorInput={error.officeId}
                        />
                    </div>
                    <div style={{ width: '31%' }}>
                        <InputLabel className="label-app">{t("common:commonClientLbl")}</InputLabel>
                        <SelectFieldComponent name="businessName"
                            valueInput={clientId}
                            onChange={onChangeClient}
                            options={[
                                { key: 'all', value: t('label:allsLbl') },
                                ...clients
                            ]}
                            errorInput={error.clientId}
                        />
                    </div>
                </div>
            </div>
            <div className="container-app-div">
                <Typography className="app-form-title">{t("subtitle4")}</Typography>
                <div style={{ marginBottom: 24 }}>
                    <InputLabel className="label-app">{t("titleLbl")}</InputLabel>
                    <TextFieldComponent name="title" valueInput={notification.title}
                        callback={onChange.bind(this)} maxLength={40} placeholder={t("titlePlh")}
                        errorInput={error.title}
                    />
                </div>
                <InputLabel className="label-app">{t("descriptionLbl")}</InputLabel>
                <TextFieldComponent
                    name="description" row={4} valueInput={notification.description}
                    maxLength={200}
                    callback={onChange.bind(this)}
                    errorInput={error.description}
                    placeholder={t("descriptionPlh")}
                />
            </div>
        </div>
    );
}
export default FirtsStep;